import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import { Divider, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useCircuitAndRotations } from "../../state-management/circuit-and-rotations-hook";
import { SourceOrder } from "../sourceOrder";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3, width: "100%" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const SourcesTabs: React.FC = (): JSX.Element => {
  const [value, setValue] = React.useState(0);
  const { vehicules, voyages, selectedLine1, selectedLine2 } =
    useCircuitAndRotations();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const plannedIds = [
    ...voyages.map((item) => item.back?.id),
    ...voyages.map((item) => item.go?.id),
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            label={
              <Stack direction={"row"} spacing={1}>
                {" "}
                <AirportShuttleOutlinedIcon fontSize="small" />{" "}
                <Typography sx={{ fontWeight: 500 }}>Navettes</Typography>{" "}
              </Stack>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Stack direction={"row"} spacing={1}>
                {" "}
                <DriveEtaOutlinedIcon fontSize="small" />{" "}
                <Typography sx={{ fontWeight: 500 }}>Privé</Typography>{" "}
              </Stack>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Stack direction={"row"} spacing={2}>
          <Box sx={{ flexGrow: 0.5 }}>
            <Typography align="center" sx={{ fontWeight: 500 }}>
              Ligne de depart
            </Typography>
            <Divider sx={{ mb: 2 }} />
            {vehicules && selectedLine1 && vehicules[selectedLine1?.id!] && (
              <Stack spacing={1}>
                {vehicules[selectedLine1?.id!]
                  .filter((item) => !plannedIds.includes(item.id))
                  .map((item, index) => (
                    <SourceOrder key={index} vehicle={item} />
                  ))}
                {vehicules[selectedLine1?.id!].length === 0 && (
                  <Box
                    sx={{
                      width: "100%",
                      height: 72,
                      backgroundColor: "#eee",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                )}
              </Stack>
            )}
          </Box>

          <Box sx={{ flexGrow: 0.5 }}>
            <Typography align="center" sx={{ fontWeight: 500 }}>
              Ligne d'arrivée
            </Typography>
            <Divider sx={{ mb: 2 }} />
            {vehicules && selectedLine2 && vehicules[selectedLine2?.id!] && (
              <Stack spacing={1}>
                {vehicules[selectedLine2?.id!]
                  .filter((item) => !plannedIds.includes(item.id))
                  .map((item, index) => (
                    <SourceOrder key={index} vehicle={item} />
                  ))}
                {vehicules[selectedLine2?.id!].length === 0 && (
                  <Box
                    sx={{
                      width: "100%",
                      height: 72,
                      backgroundColor: "#eee",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                )}
              </Stack>
            )}
          </Box>
        </Stack>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <Box sx={{ height: 35 }} />
          <Stack spacing={1}>
            {vehicules && vehicules["-1"] && (
              <Stack spacing={1}>
                {vehicules["-1"]
                  .filter((item) => !plannedIds.includes(item.id))
                  .map((item, index) => (
                    <SourceOrder key={index} vehicle={item} />
                  ))}
                {vehicules["-1"].length === 0 && (
                  <Box
                    sx={{
                      width: "100%",
                      height: 72,
                      backgroundColor: "#eee",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Box>
      </TabPanel>
    </Box>
  );
};
