import { Box, Typography } from "@mui/material";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorRoutePage() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <Box>
        <Typography variant="h1" sx={{ fontWeight: 500 }}>
          Oops!
        </Typography>
        <Typography variant="h2">{error.status}</Typography>
        <Typography>{error.statusText}</Typography>
        {error.data?.message && <Typography>{error.data.message}</Typography>}
      </Box>
    );
  } else {
    return (
      <Box
        id="error-page"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: 500,
        }}
      >
        <Typography variant="h1" align="center" sx={{ fontWeight: 500 }}>
          Oops!
        </Typography>
        <Typography align="center">
          Désolé, une erreur inattendue s'est produite.
        </Typography>
      </Box>
    );
  }
}
