import {
  ASSIGNMENTOFDRIVERS_ROUTE,
  ASSIGNMENTOFVEHICLES_ROUTE,
  CIRCUITANDROTATIONS_ROUTE,
  SHUTTLEMANAGEMENT_ROUTE,
} from "../constants/routeConstants";
import { MenuModel } from "../models/menu-model";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ShuttleManagementPage from "../../pages/shuttle-management";
import { CircuitAndRotationsPage } from "../../pages/circuit-and-rotations";
import { AssignmentOfVehiclesPage } from "../../pages/assignment-of-vehicles";
import { AssignmentOfDriversPage } from "../../pages/assignment-of-drivers";

export const MenuItems: MenuModel[] = [
  {
    label: "Gestion des Navettes",
    path: SHUTTLEMANAGEMENT_ROUTE,
    visibility: true,
    index: true,
    icon: () => DashboardOutlinedIcon,
    breadcrumb: "Gestion des Navettes",
    element: ShuttleManagementPage,
  },
  {
    label: "Affectation des Vehicules",
    path: ASSIGNMENTOFVEHICLES_ROUTE,
    visibility: true,
    icon: () => DashboardOutlinedIcon,
    breadcrumb: "Affectation des Vehicules",
    element: AssignmentOfVehiclesPage!,
  },
  {
    label: "Circuit & Rotations",
    path: CIRCUITANDROTATIONS_ROUTE,
    visibility: true,
    icon: () => DashboardOutlinedIcon,
    breadcrumb: "Circuit & Rotations",
    element: CircuitAndRotationsPage!,
  },

  {
    label: "Affectation des Chauffeurs",
    path: ASSIGNMENTOFDRIVERS_ROUTE,
    visibility: true,
    icon: () => DashboardOutlinedIcon,
    breadcrumb: "Affectation des Chauffeurs",
    element: AssignmentOfDriversPage!,
  },
];
