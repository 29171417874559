import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { FC } from "react";
import { LineStopModel } from "../../../../application/models/trip-line-model";

export interface StopsListPropsModel {
  handleItemClick: (id: number) => void;
  stops: LineStopModel[];
  selected: number[];
}

export const StopsList: FC<StopsListPropsModel> = ({
  handleItemClick,
  stops,
  selected,
}): JSX.Element => {
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    handleItemClick(index);
  };

  return (
    <List component="nav" dense aria-label="main mailbox folders">
      {(stops || []).map((stop, index) => (
        <ListItemButton
          key={index}
          selected={selected.includes(stop.id)}
          onClick={(event) => handleListItemClick(event, stop.id)}
        >
          <ListItemText primary={stop.id_str} />
          {selected.includes(stop.id) ? (
            <CheckBoxOutlinedIcon color="success" />
          ) : (
            <CheckBoxOutlineBlankOutlinedIcon />
          )}
        </ListItemButton>
      ))}
    </List>
  );
};
