import React from "react";
import { RouteObject } from "react-router-dom";
import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import ErrorRoutePage from "../../pages/errors/error-route-page";
import ShuttleManagementPage from "../../pages/shuttle-management";
import { MenuItems } from "../data/menuItems";
import { Layout } from "../layout";

export const getRoutes = (): RouteObject[] => {
  return [
    {
      element: <Layout />,
      path: "/",
      children: [
        {
          index: true,
          element: <ShuttleManagementPage />,
        },
        ...MenuItems.map((item) => {
          const Component = item.element;
          return {
            path: item.path,
            onLeave: () => console.log("edededed"),
            element: <Component />,
            errorElement: <ErrorRoutePage />,
          };
        }),
      ],
    },
  ];
};

export const getBreadcrumbsRoute = (): BreadcrumbsRoute[] => {
  return MenuItems.map(({ path, breadcrumb }) => ({
    path,
    breadcrumb,
  }));
};
