import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { theme } from "./application/theme";
import { getRoutes } from "./application/utils/routes-navigation";
import { ErrorFallback } from "./components/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";

const root = ReactDOM.createRoot(
  document.getElementById("ozeroute_scheduler") as HTMLElement
);

const router = createBrowserRouter(getRoutes());

root.render(
  <DndProvider
    // backend={TouchBackend}
    // options={{
    //   enableTouchEvents: true,
    //   enableMouseEvents: true,
    //   enableHoverOutsideTarget: false,
    // }}
    backend={HTML5Backend}
  >
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={frLocale}
      >
        <CssBaseline />
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            console.log("");
          }}
        >
          <RouterProvider router={router} />
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  </DndProvider>
);
