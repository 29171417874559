import { Alert, Box, Button, IconButton, Stack } from "@mui/material";
import { FC, useEffect } from "react";
import { BreadcrumbsRouter } from "../../components/breadcrumbs-router";
import { CircuitAndRotationsFormFilter } from "./components/form-filter";
import { SourcesTabs } from "./components/sources-tabs";
import { TargetContainer } from "./components/target-container";
import { useCircuitAndRotations } from "./state-management/circuit-and-rotations-hook";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { RequestStatusModel } from "../../application/models/request-status-model";
import CloseIcon from "@mui/icons-material/Close";

export const CircuitAndRotationsPage: FC = (): JSX.Element => {
  const {
    init,
    resetState,
    date,
    selectedLine1,
    selectedLine2,
    fetchVehicules,
    fetchVoyages,
    save,
    requestSaveStatus,
    isValideData,
    razRequestSaveStatus,
  } = useCircuitAndRotations();

  useEffect(() => {
    init();
    return () => {
      resetState();
    };
  }, []);

  useEffect(() => {
    if (date && selectedLine1 && selectedLine2) {
      fetchVehicules();
    }
  }, [date, selectedLine1, selectedLine2]);

  useEffect(() => {
    fetchVoyages();
  }, [date]);

  return (
    <Stack direction={"column"} spacing={3} sx={{ mt: 0 }}>
      <Box sx={{ float: "left" }}>
        {/* <SchedulerBreadcrumbs /> */}
        <BreadcrumbsRouter />
      </Box>
      <Box>
        <CircuitAndRotationsFormFilter />
      </Box>{" "}
      <Box>
        <Stack direction={"row"}>
          {" "}
          <Box sx={{ flexGrow: 1 }}>
            {(requestSaveStatus === RequestStatusModel.failure ||
              requestSaveStatus === RequestStatusModel.success) && (
              <Alert
                severity={
                  requestSaveStatus === RequestStatusModel.failure
                    ? "error"
                    : "success"
                }
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      razRequestSaveStatus();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {requestSaveStatus === RequestStatusModel.failure
                  ? "Une erreur se produit lors de l'enregistrement!"
                  : "Enregistrement a été effectué avec succès"}
              </Alert>
            )}{" "}
          </Box>
          <Button
            sx={{ float: "right" }}
            variant="contained"
            disabled={
              requestSaveStatus === RequestStatusModel.loading ||
              isValideData() === false
            }
            startIcon={<SaveOutlinedIcon />}
            onClick={() => {
              save();
            }}
          >
            Enregistrer
          </Button>
        </Stack>
      </Box>
      <Stack direction={"row"} spacing={2}>
        <Box sx={{ width: 450 }}>
          <SourcesTabs />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <TargetContainer />{" "}
        </Box>
      </Stack>
    </Stack>
  );
};
