import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { FC, useState } from "react";
import { useCircuitAndRotations } from "../../state-management/circuit-and-rotations-hook";
import { OrderWidget } from "../order-widget";
import { RoundTripType, TargetOrder } from "../targetOrder";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TargetContainer: FC = (): JSX.Element => {
  const { voyages } = useCircuitAndRotations();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const lastItemIndex = voyages.length;

  const showNewWorkSpace =
    lastItemIndex === 0 ||
    (lastItemIndex > 0 && voyages[lastItemIndex - 1]?.go !== undefined);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab label="Voyages" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"row"} justifyContent="space-between" spacing={2}>
            <Box sx={{ flexGrow: 0.5 }}>
              <Typography align="center" sx={{ fontWeight: 500 }}>
                ALLÉ
              </Typography>
              <Box sx={{ mb: 2 }} />
            </Box>
            <Box sx={{ flexGrow: 0.5 }}>
              <Typography align="center" sx={{ fontWeight: 500 }}>
                RETOUR
              </Typography>
              <Box sx={{ mb: 2 }} />
            </Box>
          </Stack>
        </Stack>

        <Stack direction={"column"} spacing={1}>
          {voyages
            .filter((item) => item.go !== undefined || item.back !== undefined)
            .map((item, index) => (
              <Stack
                key={index}
                direction={"row"}
                justifyContent="space-between"
                spacing={2}
              >
                <Box sx={{ width: "50%" }}>
                  {item.go !== undefined ? (
                    <OrderWidget vehicle={item.go} canBacktrack={true} />
                  ) : (
                    <TargetOrder
                      index={index}
                      roundTripType={RoundTripType.GO}
                    />
                  )}
                </Box>
                <Box sx={{ width: "50%" }}>
                  {item.back !== undefined ? (
                    <OrderWidget vehicle={item.back} canBacktrack={true} />
                  ) : (
                    <TargetOrder
                      index={index}
                      roundTripType={RoundTripType.BACK}
                    />
                  )}
                </Box>
              </Stack>
            ))}

          {showNewWorkSpace && (
            <Stack direction={"row"} justifyContent="space-between" spacing={2}>
              <Box sx={{ width: "50%" }}>
                <TargetOrder
                  index={lastItemIndex!}
                  roundTripType={RoundTripType.GO}
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <TargetOrder
                  index={lastItemIndex!}
                  roundTripType={RoundTripType.BACK}
                />
              </Box>
            </Stack>
          )}
        </Stack>
      </TabPanel>
    </Box>
  );
};
