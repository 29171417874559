import { Box } from "@mui/material";
import { FC } from "react";
import { useDrop } from "react-dnd";
import {
  RootOrderModel,
  RootOrderTypeModel,
} from "../../../../application/repositories/assignment-of-vehicles-repository";
import { useAssignmentOfVehicles } from "../../state-management/assignment-of-vehicles-hook";

export const DropVehicleContainer: FC<{
  rootOrder: RootOrderModel;
}> = ({ rootOrder }): JSX.Element => {
  const { vehicles } = useAssignmentOfVehicles();
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept:
        rootOrder.type === RootOrderTypeModel.N
          ? vehicles.map((v) => String(v.id))
          : vehicles.map((v) => String(v.id)),
      // : String(rootOrder.vehicle_type_id),

      drop: () => ({ ...rootOrder }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [rootOrder]
  );

  const isActive = canDrop && isOver;
  let backgroundColor = "#ecedbe";
  if (isActive) {
    backgroundColor = "secondary.main";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }

  return (
    <Box
      ref={drop}
      sx={{
        transition: "height 0.3s ease-in-out",
        borderStyle: "dashed solid",
        borderWidth: canDrop ? 1 : 0,
        borderColor: "#ddd",
        borderRadius: 1,
        height: canDrop ? 90 : 0,
        width: "100%",
        color: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor,
        overflow: "hidden",
      }}
      data-testid="dustbin"
    >
      {isActive ? "Release to drop" : "Faites glisser un type de vehicule ici"}
    </Box>
  );
};
