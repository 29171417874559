import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { RequestStatusModel } from "../../application/models/request-status-model";
import { BreadcrumbsRouter } from "../../components/breadcrumbs-router";
import { AssignmentOfVehiclesFormFilter } from "./components/form-filter";
import { ListOrders } from "./components/list-orders";
import { ListVehicleTypes } from "./components/list-vehicle-types";
import { useAssignmentOfVehicles } from "./state-management/assignment-of-vehicles-hook";
import CloseIcon from "@mui/icons-material/Close";

export const AssignmentOfVehiclesPage: FC = (): JSX.Element => {
  const {
    init,
    date,
    startHour,
    endHour,
    selectedLine,
    fetch,
    requestFetchStatus,
    razRequestSaveStatus: hideMsgError,
    save,

    requestSaveStatus,
  } = useAssignmentOfVehicles();
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (date && selectedLine) {
      fetch();
    }
  }, [date, selectedLine, startHour, endHour]);

  useEffect(() => {
    if (requestSaveStatus === RequestStatusModel.success) {
      const timer = setTimeout(() => {
        fetch();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [requestSaveStatus]);

  return (
    <Stack direction={"column"} spacing={3} sx={{ mt: 0 }}>
      <Box sx={{ float: "left" }}>
        <BreadcrumbsRouter />
      </Box>

      <Box>
        <AssignmentOfVehiclesFormFilter />{" "}
      </Box>

      <Stack direction={"row"}>
        <Button
          variant="contained"
          disabled={requestSaveStatus === RequestStatusModel.loading}
          startIcon={<SaveOutlinedIcon />}
          onClick={() => {
            save();
          }}
        >
          Enregistrer
        </Button>

        {(requestSaveStatus === RequestStatusModel.failure ||
          requestSaveStatus === RequestStatusModel.success) && (
          <Box sx={{ flexGrow: 1 }}>
            <Alert
              severity={
                requestSaveStatus === RequestStatusModel.failure
                  ? "error"
                  : "success"
              }
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    hideMsgError();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {requestSaveStatus === RequestStatusModel.failure
                ? "Une erreur se produit lors de l'enregistrement!"
                : "Enregistrement a été effectué avec succès"}
            </Alert>
          </Box>
        )}
      </Stack>

      <Stack direction={"row"} spacing={2} alignItems="stretch">
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ fontWeight: 500, mb: 1 }}>
            Commandes & Affectations
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {requestFetchStatus === RequestStatusModel.loading && (
            <LinearProgress />
          )}

          {requestFetchStatus === RequestStatusModel.success && <ListOrders />}
        </Box>

        <Box sx={{ width: 350 }}>
          <Typography sx={{ fontWeight: 500, mb: 1 }}>
            Type de véhicule
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "fixed",
                width: 350,
                maxHeight: 400,
                overflowY: "scroll",
                p: 1,
              }}
            >
              <ListVehicleTypes />
            </Box>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
