import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { IconButton, Tooltip } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";

const SwitchDirectionButton: FC<{ switchDirection: () => void }> = (props) => {
  const [state, setState] = useState({ rotate: false, toggle: false });

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener("animationend", rotatingDone);
    }
    return () =>
      ref?.current
        ? ref?.current.removeEventListener("animationend", rotatingDone)
        : undefined;
  }, []);

  const rotatingDone = () => {
    setState((state) => ({
      ...state,
      toggle: !state.toggle,
      rotate: false,
    }));
  };

  return (
    <Tooltip title="Inverser L'itinéraire" placement="top">
      <IconButton
        ref={ref}
        color="primary"
        size="small"
        style={{ border: "solid 1px #c4c4c4", backgroundColor: "#eee" }}
        onClick={() => {
          setState((state) => ({ ...state, rotate: true }));
          setTimeout(() => {
            props.switchDirection();
          }, 200);
        }}
        sx={
          state.rotate
            ? { transition: `transform 200ms`, transform: `rotate(180deg)` }
            : {}
        }
      >
        <CompareArrowsIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SwitchDirectionButton;
