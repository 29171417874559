import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link } from "@mui/material";
import React, { FC } from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { getBreadcrumbsRoute } from "../../application/utils/routes-navigation";

export const BreadcrumbsRouter: FC = (): JSX.Element => {
  const breadcrumbs = useBreadcrumbs(getBreadcrumbsRoute(), {
    excludePaths: ["/"],
  });

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <span key={match.pathname}>
          <Link underline="hover" href={match.pathname}>
            {breadcrumb}
          </Link>
        </span>
      ))}
    </Breadcrumbs>
  );
};
