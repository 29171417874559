import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export const FormSkeleton: React.FC = (): JSX.Element => {
  return (
    <Stack spacing={2}>
      <Stack direction={"row"} spacing={2}>
        <Skeleton variant="rectangular" width="50%" height={40} />
        <Skeleton variant="rectangular" width="50%" height={40} />
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <Skeleton variant="rectangular" width="50%" height={40} />
        <Skeleton variant="rectangular" width="50%" height={40} />
      </Stack>
    </Stack>
  );
};
