import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FC } from "react";

export interface DatePickerButtonProps {
  minDate?: Date;
  label: string;
  value: Date | null;
  onChange: (
    value: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
}

export const DatePickerButton: FC<DatePickerButtonProps> = (
  props
): JSX.Element => {
  return (
    <DatePicker
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          inputProps={{
            ...params.inputProps,
            readOnly: true,
          }}
          fullWidth
        />
      )}
    />
  );
};
