import { format } from "date-fns";
import create from "zustand";
import { ActivitySiteModel } from "../../../application/models/activity-site-model";
import { RequestStatusModel } from "../../../application/models/request-status-model";
import { TripLineModel } from "../../../application/models/trip-line-model";
import { RouteModel } from "../../../application/models/trip-model";
import { VehicleTypeModel } from "../../../application/models/vehicle-model";
import { fetchActivitySites } from "../../../application/repositories/activity-sites-repository";
import { fetchTripsLines } from "../../../application/repositories/trip_repository";
import { RoundTripType } from "../components/targetOrder";
import { VehicleModel, vehiculesModel, VoyageModel } from "../models";
import {
  fetchCircuitRotationsVehiculesList,
  fetchCircuitRotationsVoyagesList,
  SaveVoyages,
} from "../repositories";
import { fetchVehicleTypes } from "./../../../application/repositories/vehicles_repository";

export enum RowDirection {
  ROW_REVERSE = "row-reverse",
  ROW = "row",
}

export interface CircuitAndRotationsHookModel {
  requestInitStatus: RequestStatusModel;
  requestVehiclesStatus: RequestStatusModel;
  requestVoyagesStatus: RequestStatusModel;
  requestSaveStatus: RequestStatusModel;
  rowDirection: RowDirection;
  activitySites: ActivitySiteModel[];
  tripLines: TripLineModel[];
  date: Date;
  selectedActivitySite: ActivitySiteModel | null;
  selectedLine1: TripLineModel | null;
  selectedLine2: TripLineModel | null;
  vehiculeTypes: VehicleTypeModel[];
  vehicules: vehiculesModel | undefined;
  voyages: VoyageModel[];
  dataTrips1: Record<number, RouteModel[]> | undefined;
  dataTrips2: Record<number, RouteModel[]> | undefined;
  resetState: () => void;
  planningTrip: (
    index: number,
    roundTripType: RoundTripType,
    vehicule: VehicleModel,
    vehicle_type_id?: number
  ) => void;
  backtrackPlannedOrder: (order: VehicleModel) => void;
  toggleRowDirection: () => void;
  setDate: (date: Date) => void;
  setSelectedActivitySite: (
    selectedActivitySite: ActivitySiteModel | null
  ) => void;
  setSelectedLine1: (selectedLine1: TripLineModel | null) => void;
  setSelectedLine2: (selectedLine2: TripLineModel | null) => void;
  fetchVehicules: () => void;
  fetchVoyages: () => void;
  init: () => void;
  save: () => void;
  razRequestSaveStatus: () => void;
  isValideData: () => boolean;
}

export const useCircuitAndRotations = create<CircuitAndRotationsHookModel>(
  (set, get) => ({
    requestInitStatus: RequestStatusModel.initial,
    requestVehiclesStatus: RequestStatusModel.initial,
    requestVoyagesStatus: RequestStatusModel.initial,
    requestSaveStatus: RequestStatusModel.initial,
    rowDirection: RowDirection.ROW,
    activitySites: [],
    tripLines: [],
    date: new Date(),
    selectedActivitySite: null,
    selectedLine1: null,
    selectedLine2: null,
    vehiculeTypes: [],
    vehicules: undefined,
    voyages: [],
    dataTrips1: undefined,
    dataTrips2: undefined,
    resetState: () => {
      set((state) => ({
        ...state,
        isLoading: false,
        isError: false,
        rowDirection: RowDirection.ROW,
        activitySites: [],
        tripLines: [],
        date: new Date(),
        selectedActivitySite: null,
        selectedLine1: null,
        selectedLine2: null,
        data: undefined,
        dataTrips1: undefined,
        dataTrips2: undefined,
        plannedTrips: [],
      }));
    },
    razRequestSaveStatus: () => {
      set((state) => ({
        ...state,
        requestSaveStatus: RequestStatusModel.initial,
      }));
    },
    isValideData: () => {
      const emptyGo = get().voyages.find((item) => item.go === undefined);
      return emptyGo ? false : true;
    },
    planningTrip: (
      index: number,
      roundTripType: RoundTripType,
      vehicule: VehicleModel,
      vehicle_type_id?: number
    ) => {
      if (index >= get().voyages.length) {
        set((state) => ({
          ...state,
          voyages: [
            ...state.voyages,
            {
              go: roundTripType === RoundTripType.GO ? vehicule : undefined,
              back: roundTripType === RoundTripType.BACK ? vehicule : undefined,
              vehicle_type_id: vehicle_type_id ?? undefined,
            },
          ],
        }));
      } else {
        set((state) => ({
          ...state,
          voyages: state.voyages.map((item, idx) => {
            if (idx !== index) {
              return item;
            }

            return {
              ...item,
              go: roundTripType === RoundTripType.GO ? vehicule : item?.go,
              back:
                roundTripType === RoundTripType.BACK ? vehicule : item?.back,
              vehicle_type_id: vehicle_type_id ?? undefined,
            };
          }),
        }));
      }
    },
    backtrackPlannedOrder: (order: VehicleModel) => {
      set((state) => ({
        ...state,
        voyages: state.voyages
          .map((item, idx) => {
            return {
              ...item,
              go: item.go?.id === order.id ? item.back : item.go,
              back:
                item?.back?.id === order.id || item.go?.id === order.id
                  ? undefined
                  : item.back,
            };
          })
          .filter((item) => item.go !== undefined || item.back !== undefined),
      }));
    },
    save: () => {
      set((state) => ({
        ...state,
        requestSaveStatus: RequestStatusModel.loading,
      }));
      SaveVoyages({
        date: format(get().date, "yyyy-MM-dd"),
        voyages: get().voyages,
      })
        .then((response) => {
          set((state) => ({
            ...state,
            requestSaveStatus: RequestStatusModel.success,
          }));
        })
        .catch((error) => {
          set((state) => ({
            ...state,
            requestSaveStatus: RequestStatusModel.failure,
          }));
        });
    },
    toggleRowDirection: () => {
      set((state) => ({
        ...state,
        rowDirection:
          state.rowDirection === RowDirection.ROW
            ? RowDirection.ROW_REVERSE
            : RowDirection.ROW,
      }));
    },

    setDate: (date: Date) => {
      set((state) => ({
        ...state,
        date,
      }));
    },
    setSelectedActivitySite: (
      selectedActivitySite: ActivitySiteModel | null
    ) => {
      set((state) => ({
        ...state,
        selectedActivitySite,
        selectedLine1: null,
        selectedLine2: null,
        tripLines: [],
      }));
      fetchTripsLines(selectedActivitySite?.id).then((response) => {
        const _selectedLine1 = response.lines[0];
        const _selectedLine2 =
          response.lines.find(
            (item) =>
              item.extrem1_id === _selectedLine1.extrem2_id &&
              item.extrem2_id === _selectedLine1.extrem1_id
          ) ||
          response.lines.find(
            (item) =>
              item.extrem1_id !== _selectedLine1.extrem1_id &&
              item.extrem2_id !== _selectedLine1.extrem2_id
          );

        set((state) => ({
          ...state,
          selectedLine1: _selectedLine1,
          selectedLine2: _selectedLine2,
          tripLines: response.lines,
        }));
      });
    },
    setSelectedLine1: (selectedLine1: TripLineModel | null) => {
      set((state) => ({
        ...state,
        selectedLine1,
      }));
    },
    setSelectedLine2: (selectedLine2: TripLineModel | null) => {
      set((state) => ({
        ...state,
        selectedLine2,
      }));
    },
    init: () => {
      set((state) => ({
        ...state,
        requestInitStatus: RequestStatusModel.loading,
        selectedLine1: undefined,
        selectedLine2: undefined,
        voyages: [],
        vehicules: undefined,
      }));

      Promise.all([
        fetchActivitySites(),
        fetchTripsLines(),
        fetchVehicleTypes(),
      ])
        .then(([activitySites, tripLines, vehiculeTypes]) => {
          const _selectedLine1 = tripLines.lines[0];
          const _selectedLine2 =
            tripLines.lines.find(
              (item) =>
                item.extrem1_id === _selectedLine1.extrem2_id &&
                item.extrem2_id === _selectedLine1.extrem1_id
            ) ||
            tripLines.lines.find(
              (item) =>
                item.extrem1_id !== _selectedLine1.extrem1_id &&
                item.extrem2_id !== _selectedLine1.extrem2_id
            );

          set((state) => ({
            ...state,
            requestInitStatus: RequestStatusModel.success,
            vehiculeTypes,
            activitySites,
            tripLines: tripLines.lines,
            selectedLine1: _selectedLine1,
            selectedLine2: _selectedLine2,
          }));
        })
        .catch((error) => {
          set((state) => ({
            ...state,
            requestInitStatus: RequestStatusModel.failure,
          }));
        });
    },
    fetchVoyages: () => {
      set((state) => ({
        ...state,
        requestVoyagesStatus: RequestStatusModel.loading,
      }));

      fetchCircuitRotationsVoyagesList({
        date: format(get().date, "yyyy-MM-dd"),
      })
        .then((response) => {
          set((state) => ({
            ...state,
            requestVoyagesStatus: RequestStatusModel.success,
            voyages: response?.voyages ? response?.voyages : [],
          }));
        })
        .catch((error) => {
          set((state) => ({
            ...state,
            requestVoyagesStatus: RequestStatusModel.failure,
          }));
        });
    },
    fetchVehicules: async () => {
      set((state) => ({
        ...state,
        requestVehiclesStatus: RequestStatusModel.loading,
      }));

      fetchCircuitRotationsVehiculesList({
        date: format(get().date, "yyyy-MM-dd"),
        lines: [get().selectedLine1?.id!, get().selectedLine2?.id!],
      })
        .then((response) => {
          set((state) => ({
            ...state,
            requestVehiclesStatus: RequestStatusModel.success,
            vehicules: response?.vehicules ? response?.vehicules : undefined,
          }));
        })
        .catch((error) => {
          set((state) => ({
            ...state,
            requestVehiclesStatus: RequestStatusModel.failure,
          }));
        });
    },
  })
);
