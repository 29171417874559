import ListIcon from "@mui/icons-material/List";
import { ClickAwayListener, IconButton, Paper, Popper } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import * as Scroll from "react-scroll";
import { RouteModel } from "../../../../application/models/trip-model";
import {
  RowDirection,
  useScheduler,
} from "../../state-management/scheduler-hook";
import { SummarizerListTrips } from "../trip-table/summarizer";

type FctTargetModel = Record<
  RowDirection,
  Record<
    string,
    {
      fct: (
        time: string,
        dataTarget: Record<number, RouteModel[]> | undefined,
        selectedStopsTarget: number[]
      ) => RouteModel[];
      params: {
        time: string;
        dataTarget: Record<number, RouteModel[]> | undefined;
        selectedStopsTarget: number[];
      };
    }
  >
>;

export const TripActionColumn: FC<{ tripsOrder: number; trip: RouteModel }> = ({
  tripsOrder,
  trip,
}): JSX.Element => {
  const scroller = Scroll.scroller;
  const {
    data,
    rowDirection,
    getAfter,
    getBefore,
    setTargetTripId,
    dataTrips1,
    dataTrips2,
    selectedStopsLine1,
    selectedStopsLine2,
  } = useScheduler();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [items, setItems] = useState<RouteModel[]>([]);
  useEffect(() => {
    const functionName = fctTarget[rowDirection][String(tripsOrder)].fct;
    const functionParams = fctTarget[rowDirection][String(tripsOrder)].params;
    const _items = functionName(
      functionParams.time,
      functionParams.dataTarget,
      functionParams.selectedStopsTarget
    );
    setItems(_items);
  }, [dataTrips1, dataTrips2, tripsOrder, rowDirection]);

  const dataTarget = tripsOrder === 1 ? dataTrips2 : dataTrips1;
  const selectedStopsTarget =
    tripsOrder === 1 ? selectedStopsLine2 : selectedStopsLine1;

  const fctTarget: FctTargetModel = {
    [RowDirection.ROW]: {
      "1": {
        fct: getAfter,
        params: {
          time: trip.arriving_time,
          dataTarget,
          selectedStopsTarget,
        },
      },
      "2": {
        fct: getBefore,
        params: {
          time: trip.departure_time,
          dataTarget,
          selectedStopsTarget,
        },
      },
    },
    [RowDirection.ROW_REVERSE]: {
      "1": {
        fct: getBefore,
        params: {
          time: trip.departure_time,
          dataTarget,
          selectedStopsTarget,
        },
      },
      "2": {
        fct: getAfter,
        params: {
          time: trip.arriving_time,
          dataTarget,
          selectedStopsTarget,
        },
      },
    },
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (items.length > 0) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F5F5F5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 36,
          backgroundColor: "primary.main",
          color: "#fff",
          fontWeight: 500,
          fontSize: 14,

          // lineHeight: "1.5rem",
        }}
      >
        Action
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            p: 1,

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <IconButton
            disabled={items.length === 0}
            color={items.length > 0 ? "secondary" : "default"}
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <ListIcon />
          </IconButton>

          <Popper
            id={id}
            disablePortal={true}
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            sx={{
              zIndex: 2000,
              '&[x-placement*="bottom"] $arrow': {
                width: 0,
                height: 0,
                borderLeft: "1em solid transparent",
                borderRight: "1em solid transparent",
                borderBottom: "1em solid #2c3e50",
                marginTop: "-1em",
                "&::before": {
                  borderWidth: "0 1em 1em 1em",
                  borderColor: "transparent transparent white transparent",
                },
              },
            }}
            modifiers={[
              {
                name: "arrow",
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
            ]}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box>
                <Box
                  ref={setArrowRef}
                  sx={{
                    position: "absolute",
                    display: "inline-block",
                    height: 0,
                    width: 0,
                    borderRight: "6px solid transparent",
                    borderBottom: "12px solid #2A3F54",
                    borderLeft: "6px solid transparent",
                  }}
                />
                <Box sx={{ pt: 1.5 }}>
                  <Paper
                    sx={{
                      p: 0,
                      backgroundColor: "#F5F5F5",
                      border: "solid 1px #2A3F54",
                      width: 400,
                    }}
                    elevation={11}
                  >
                    <SummarizerListTrips
                      stops={
                        tripsOrder === 1
                          ? (data?.stops2 || []).filter((stop) =>
                              selectedStopsLine2.includes(stop.id)
                            )
                          : (data?.stops1 || []).filter((stop) =>
                              selectedStopsLine1.includes(stop.id)
                            )
                      }
                      tripsOrder={tripsOrder}
                      items={items || []}
                      onClick={(item) => {
                        scroller.scrollTo(`${item.id}`, {
                          duration: 500,
                          smooth: true,
                          offset: -200,
                          spy: true,
                        });
                        setTimeout(() => {
                          setTargetTripId(item.id);
                        }, 500);

                        handleClickAway();
                      }}
                    />
                  </Paper>
                </Box>
              </Box>
            </ClickAwayListener>
          </Popper>
        </Box>
      </Box>
    </Paper>
  );
};
