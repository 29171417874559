import {
  addMinutes,
  differenceInMinutes,
  format,
  getHours,
  parse,
} from "date-fns";
import _ from "lodash";
import create from "zustand";
import { immer } from "zustand/middleware/immer";
import { ActivitySiteModel } from "../../../application/models/activity-site-model";
import { OrphoOrderModel } from "../../../application/models/orpho-order-model";
import { RequestStatusModel } from "../../../application/models/request-status-model";
import {
  RotationModel,
  TripLineModel,
} from "../../../application/models/trip-line-model";
import {
  AvailabilityStopModel,
  RouteModel,
  TripsModel,
} from "../../../application/models/trip-model";
import { fetchActivitySites } from "../../../application/repositories/activity-sites-repository";
import {
  FetchTripsParamsModel,
  fetchOrphorders,
  fetchTrips,
  fetchTripsLines,
  resetTripsLine,
  saveTripsLines,
} from "../../../application/repositories/trip_repository";
import { saveOrphorders } from "./../../../application/repositories/trip_repository";

export enum RowDirection {
  ROW_REVERSE = "row-reverse",
  ROW = "row",
}

export interface SchedulerHookModel {
  isDirty: boolean;
  requestFetchOrphoStatus: RequestStatusModel;
  requestSaveOrphoStatus: RequestStatusModel;
  requestSaveStatus: RequestStatusModel;
  requestFetchStatus: RequestStatusModel;
  requestRazStatus: RequestStatusModel;
  requestInitStatus: RequestStatusModel;
  showSummarizer: boolean;
  rowDirection: RowDirection;
  currentTask: SchedulerHookTask;
  targetTripId: number | undefined;
  activitySites: ActivitySiteModel[];
  tripLines: TripLineModel[];
  rotations: RotationModel[];
  orphoOrders: OrphoOrderModel[];
  date: Date;
  dateFin: Date | null;
  isSameStops: boolean;
  selectedActivitySite: ActivitySiteModel | null;
  selectedRotation: RotationModel | null;
  selectedLine1: TripLineModel | null;
  selectedLine2: TripLineModel | null;
  selectedStopsLine1: number[];
  selectedStopsLine2: number[];
  data: TripsModel | undefined;
  dataTrips1: Record<number, RouteModel[]> | undefined;
  dataTrips2: Record<number, RouteModel[]> | undefined;
  flatDataTrip: (
    dataTrip: Record<number, RouteModel[]> | undefined
  ) => RouteModel[];
  setTargetTripId: (id: number | undefined) => void;
  getAfter: (
    time: string,
    dataTarget: Record<number, RouteModel[]> | undefined,
    selectedStopsTarget: number[]
  ) => RouteModel[];
  getBefore: (
    time: string,
    dataTarget: Record<number, RouteModel[]> | undefined,
    selectedStopsTarget: number[]
  ) => RouteModel[];
  changeTripsTime: (props: {
    dataTrips: RouteModel[];
    activeStops: number[];
  }) => Record<number, RouteModel[]>;
  toggleRowDirection: () => void;
  handleClickStopLine1: (id: number) => void;
  handleClickStopLine2: (id: number) => void;
  setDate: (date: Date) => void;
  setSelectedActivitySite: (
    selectedActivitySite: ActivitySiteModel | null
  ) => void;
  setRotation: (rotation: RotationModel | null) => void;
  setSelectedLine1: (selectedLine1: TripLineModel | null) => void;
  setSelectedLine2: (selectedLine2: TripLineModel | null) => void;
  fetch: (params: FetchTripsParamsModel) => void;
  groupByTime: (trips: RouteModel[]) => Record<number, RouteModel[]>;
  mergeHours: (hours1: string[], hours2: string[]) => number[];
  setDateFin: (dateFin: Date | null) => void;
  init: () => void;
  changeCapacityInTrips1: (
    hour: number,
    idx: number,
    stopId: number,
    capacity: number
  ) => void;
  changeCapacityInTrips2: (
    hour: number,
    idx: number,
    stopId: number,
    capacity: number
  ) => void;
  changeCapacity: (
    dataTrips: Record<number, RouteModel[]>,
    hour: number,
    idx: number,
    stopId: number,
    capacity: number
  ) => Record<number, RouteModel[]>;
  changeStopTimeInTrips1: (
    hour: number,
    idx: number,
    stopId: number,
    time: string
  ) => void;
  changeStopTimeInTrips2: (
    hour: number,
    idx: number,
    stopId: number,
    time: string
  ) => void;
  changeTime: (
    dataTrips: Record<number, RouteModel[]>,
    hour: number,
    idx: number,
    stopId: number,
    time: string
  ) => Record<number, RouteModel[]>;
  addMinutesToTime: (minutes: number, time: string) => String;
  setShowSummarizer: (showSummarizer: boolean) => void;
  save: () => void;
  razLineCapacity: (lineId: number) => void;
  refresh: () => void;
  resetState: () => void;
  getOrphoOrders: () => void;
  saveOrphOrders: (data: { availability: number; order: string }) => void;
}

export enum SchedulerHookTask {
  UNKNOWN = "UNKNOWN",
  INIT = "INIT",
  FETCH_LINES = "FETCH_LINES",
  FETCH = "FETCH",
  SAVE = "SAVE",
}

export const useScheduler = create(
  immer<SchedulerHookModel>((set, get) => ({
    isDirty: true,
    requestSaveOrphoStatus: RequestStatusModel.initial,
    requestFetchOrphoStatus: RequestStatusModel.initial,
    requestSaveStatus: RequestStatusModel.initial,
    requestFetchStatus: RequestStatusModel.initial,
    requestRazStatus: RequestStatusModel.initial,
    requestInitStatus: RequestStatusModel.initial,
    showSummarizer: false,
    rowDirection: RowDirection.ROW,
    currentTask: SchedulerHookTask.FETCH,
    targetTripId: undefined,
    orphoOrders: [],
    activitySites: [],
    tripLines: [],
    rotations: [],
    date: new Date(),
    dateFin: null,
    isSameStops: false,
    selectedActivitySite: null,
    selectedRotation: null,
    selectedLine1: null,
    selectedLine2: null,
    selectedStopsLine1: [],
    selectedStopsLine2: [],
    data: undefined,
    dataTrips1: undefined,
    dataTrips2: undefined,
    setDateFin: (dateFin) => {
      set((state) => {
        state.dateFin = dateFin;
      });
    },
    resetState: () => {
      set((state) => {
        state.requestSaveStatus = RequestStatusModel.initial;
        state.requestFetchStatus = RequestStatusModel.initial;
        state.requestRazStatus = RequestStatusModel.initial;
        state.requestInitStatus = RequestStatusModel.initial;
        state.showSummarizer = false;
        state.rowDirection = RowDirection.ROW;
        state.currentTask = SchedulerHookTask.FETCH;
        state.targetTripId = undefined;
        state.activitySites = [];
        state.tripLines = [];
        state.rotations = [];
        state.date = new Date();
        state.isSameStops = false;
        state.selectedActivitySite = null;
        state.selectedLine1 = null;
        state.selectedLine2 = null;
        state.selectedStopsLine1 = [];
        state.selectedStopsLine2 = [];
        state.data = undefined;
        state.dataTrips1 = undefined;
        state.dataTrips2 = undefined;
      });
    },
    flatDataTrip: (dataTrip: Record<number, RouteModel[]> | undefined) => {
      if (dataTrip === undefined) return [];
      return Object.values(dataTrip).reduce((prev, curr) => {
        return [...prev, ...curr];
      }, []);
    },
    save: () => {
      const line_id1 = get().selectedLine1?.id;
      const line_id2 = get().selectedLine2?.id;
      const toSave = {
        date: format(get().date, "yyyy-MM-dd"),
        dateFin: format(get().dateFin ?? get().date, "yyyy-MM-dd"),
        data: [
          {
            line_id: line_id1,
            routes: Object.values(get().dataTrips1!)
              .reduce((prev, curr) => {
                return [...prev, ...curr];
              }, [])
              .map((item) => ({
                id: item.id,
                route_id: item.route_id,
                availability_stops: item.availability_stops,
              })),
          },
          {
            line_id: line_id2,
            routes: Object.values(get().dataTrips2!)
              .reduce((prev, curr) => {
                return [...prev, ...curr];
              }, [])
              .map((item) => ({
                id: item.id,
                route_id: item.route_id,
                availability_stops: item.availability_stops,
              })),
          },
        ],
      };

      set((state) => {
        state.requestSaveStatus = RequestStatusModel.loading;
      });
      saveTripsLines(toSave)
        .then((response) => {
          set((state) => {
            state.requestSaveStatus = RequestStatusModel.success;
            state.isDirty = false;
          });
        })
        .catch((error) => {
          set((state) => {
            state.requestSaveStatus = RequestStatusModel.failure;
          });
        });
    },
    setShowSummarizer: (showSummarizer: boolean) => {
      set((state) => {
        state.showSummarizer = showSummarizer;
      });
    },
    setTargetTripId: (id: number | undefined) => {
      set((state) => {
        state.targetTripId = id;
      });
    },
    getAfter: (
      time: string,
      dataTarget: Record<number, RouteModel[]> | undefined,
      selectedStopsTarget: number[]
    ) => {
      const _date = parse(`${time}`, "yyyy-MM-dd HH:mm:ss", new Date());

      return Object.values(dataTarget!)
        .reduce((prev, curr) => {
          return [...prev, ...curr];
        }, [])
        .filter((item) => {
          const _departure_date = parse(
            item.departure_time,
            "yyyy-MM-dd HH:mm:ss",
            new Date()
          );
          return differenceInMinutes(_date, _departure_date) <= 0;
        });
    },
    getBefore: (
      time: string,
      dataTarget: Record<number, RouteModel[]> | undefined,
      selectedStopsTarget: number[]
    ) => {
      const _date = parse(`${time}`, "yyyy-MM-dd HH:mm:ss", new Date());

      return Object.values(dataTarget!)
        .reduce((prev, curr) => {
          return [...prev, ...curr];
        }, [])
        .filter((item) => {
          const _arriving_date = parse(
            item.arriving_time,
            "yyyy-MM-dd HH:mm:ss",
            new Date()
          );
          return differenceInMinutes(_date, _arriving_date) >= 0;
        });
    },
    changeCapacityInTrips1: (
      hour: number,
      idx: number,
      stopId: number,
      capacity: number
    ) => {
      set((state) => {
        state.isDirty = true;
        state.targetTripId = state.dataTrips1![hour][idx].id;
        state.dataTrips1 = get().changeCapacity(
          state.dataTrips1!,
          hour,
          idx,
          stopId,
          capacity
        );
      });
    },
    changeCapacityInTrips2: (
      hour: number,
      idx: number,
      stopId: number,
      capacity: number
    ) => {
      set((state) => {
        state.isDirty = true;
        state.targetTripId = state.dataTrips2![hour][idx].id;
        state.dataTrips2 = get().changeCapacity(
          state.dataTrips2!,
          hour,
          idx,
          stopId,
          capacity
        );
      });
    },
    changeCapacity: (
      dataTrips: Record<number, RouteModel[]>,
      hour: number,
      idx: number,
      stopId: number,
      capacity: number
    ) => {
      const _dataTrips = {
        ...dataTrips,
        [hour]: [
          ...dataTrips![hour].map((_item, index) => {
            if (index === idx) {
              let find = false;
              return {
                ..._item,
                availability_stops: _item.availability_stops.map((item, j) => {
                  if (item.stop_id === stopId || find === true) {
                    find = true;
                    return {
                      ...item,
                      availability:
                        capacity >= item.reserved
                          ? capacity - item.reserved
                          : item.availability,
                    };
                  }
                  return item;
                }),
              };
            }
            return _item;
          }),
        ],
      };

      return _dataTrips;
    },
    changeStopTimeInTrips1: (
      hour: number,
      idx: number,
      stopId: number,
      time: string
    ) => {
      const updatedData = Object.values(
        get().changeTime(get().dataTrips1!, hour, idx, stopId, time)
      ).reduce((prev, curr) => {
        return [...prev, ...curr];
      }, []);

      set((state) => {
        state.targetTripId = state.dataTrips1![hour][idx].id;
        state.dataTrips1 = get().changeTripsTime({
          dataTrips: updatedData,
          activeStops: get().selectedStopsLine1,
        });
      });
    },
    changeStopTimeInTrips2: (
      hour: number,
      idx: number,
      stopId: number,
      time: string
    ) => {
      const updatedData = Object.values(
        get().changeTime(get().dataTrips2!, hour, idx, stopId, time)
      ).reduce((prev, curr) => {
        return [...prev, ...curr];
      }, []);

      set((state) => {
        state.targetTripId = state.dataTrips2![hour][idx].id;
        state.dataTrips2 = get().changeTripsTime({
          dataTrips: updatedData,
          activeStops: get().selectedStopsLine2,
        });
      });
    },
    changeTime: (
      dataTrips: Record<number, RouteModel[]>,
      hour: number,
      idx: number,
      stopId: number,
      time: string
    ) => {
      const oldTime = dataTrips[hour][idx].availability_stops.find(
        (stop) => stop.stop_id === stopId
      )?.stop_time;

      const oldDate = parse(oldTime!, "yyyy-MM-dd HH:mm:ss", new Date());

      const newDate = parse(
        `${format(oldDate, "yyyy-MM-dd")} ${time}:00`,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );

      const difference = differenceInMinutes(newDate, oldDate);

      const availability_stops_updated = (() => {
        let find = false;
        const availabilities = dataTrips[hour][idx].availability_stops.map(
          (item, j) => {
            if (difference > 0 && (item.stop_id === stopId || find === true)) {
              find = true;
              return {
                ...item,
                stop_time: get().addMinutesToTime(difference, item.stop_time),
              };
            } else if (difference < 0) {
              return {
                ...item,
                stop_time: get().addMinutesToTime(difference, item.stop_time),
              };
            }
            return item;
          }
        );
        return availabilities as AvailabilityStopModel[];
      })() as AvailabilityStopModel[];

      const departure_time = availability_stops_updated[0].stop_time;
      const arriving_time =
        availability_stops_updated[availability_stops_updated.length - 1]
          .stop_time;

      const _dataTrips = {
        ...dataTrips,
        [hour]: [
          ...dataTrips![hour].map((_item, index) => {
            if (index === idx) {
              return {
                ..._item,
                departure_time,
                arriving_time,
                availability_stops: availability_stops_updated,
              };
            }
            return _item;
          }),
        ],
      };

      return _dataTrips as Record<number, RouteModel[]>;
    },
    addMinutesToTime: (minutes: number, time: string): String => {
      const result = addMinutes(
        parse(time, "yyyy-MM-dd HH:mm:ss", new Date()),
        minutes
      );
      return format(result, "yyyy-MM-dd HH:mm:ss");
    },
    toggleRowDirection: () => {
      set((state) => {
        state.targetTripId = undefined;
        state.rowDirection =
          state.rowDirection === RowDirection.ROW
            ? RowDirection.ROW_REVERSE
            : RowDirection.ROW;
      });
    },
    changeTripsTime: (props: {
      dataTrips?: RouteModel[];
      activeStops: number[];
    }) => {
      const _dataTrips = (props?.dataTrips || []).map((trip) => {
        const stops = trip.availability_stops.filter((x) =>
          props.activeStops.includes(x.stop_id)
        );
        const arriving = _.last(stops);
        const departure = _.head(stops);

        return {
          ...trip,
          arriving_time: arriving?.stop_time,
          departure_time: departure?.stop_time,
        } as RouteModel;
      });

      return get().groupByTime(_dataTrips);
    },
    handleClickStopLine1: (id: number) => {
      const selected = get().selectedStopsLine1.includes(id)
        ? get().selectedStopsLine1.filter((_id) => _id !== id)
        : [...get().selectedStopsLine1, id];

      const dataTrips1 = get().changeTripsTime({
        dataTrips: get().flatDataTrip(get().dataTrips1),
        activeStops: selected,
      });
      const dataTrips2 = get().changeTripsTime({
        dataTrips: get().flatDataTrip(get().dataTrips2),
        activeStops: selected,
      });

      set((state) => ({
        ...state,
        targetTripId: undefined,
        selectedStopsLine1: selected,
        ...(get().isSameStops ? { selectedStopsLine2: selected } : {}),
        ...(get().isSameStops ? { dataTrips1 } : {}),
        ...(get().isSameStops ? { dataTrips2 } : {}),
      }));
    },
    handleClickStopLine2: (id: number) => {
      const selected = get().selectedStopsLine2.includes(id)
        ? get().selectedStopsLine2.filter((_id) => _id !== id)
        : [...get().selectedStopsLine2, id];

      const dataTrips1 = get().changeTripsTime({
        dataTrips: get().flatDataTrip(get().dataTrips1),
        activeStops: selected,
      });
      const dataTrips2 = get().changeTripsTime({
        dataTrips: get().flatDataTrip(get().dataTrips2),
        activeStops: selected,
      });

      set((state) => ({
        ...state,
        targetTripId: undefined,
        selectedStopsLine2: selected,
        ...(get().isSameStops ? { selectedStopsLine1: selected } : {}),
        ...(get().isSameStops ? { dataTrips1 } : {}),
        ...(get().isSameStops ? { dataTrips2 } : {}),
      }));
    },
    setDate: (date: Date) => {
      set((state) => ({
        ...state,
        targetTripId: undefined,
        date,
      }));
    },
    setSelectedActivitySite: (
      selectedActivitySite: ActivitySiteModel | null
    ) => {
      set((state) => ({
        ...state,
        selectedActivitySite,
        selectedLine1: null,
        selectedLine2: null,
        tripLines: [],
        rotations: [],
        currentTask: SchedulerHookTask.FETCH_LINES,
      }));
      fetchTripsLines(selectedActivitySite?.id).then((response) => {
        const _selectedLine1 = response.lines[0];
        const _selectedLine2 =
          response.lines.find(
            (item) =>
              item.extrem1_id === _selectedLine1.extrem2_id &&
              item.extrem2_id === _selectedLine1.extrem1_id
          ) ||
          response.lines.find(
            (item) =>
              item.extrem1_id !== _selectedLine1.extrem1_id &&
              item.extrem2_id !== _selectedLine1.extrem2_id
          );

        const line1Stops = _selectedLine1.stops.map((item) => item.id);
        const line2Stops = _selectedLine2!.stops.map((item) => item.id);

        set((state) => ({
          ...state,
          targetTripId: undefined,
          selectedLine1: _selectedLine1,
          selectedLine2: _selectedLine2,
          selectedStopsLine1: [...line1Stops],
          selectedStopsLine2: [...line2Stops],
          isSameStops: _.isEqual([...line1Stops], [...line2Stops].reverse()),
          tripLines: response.lines,
          rotations: response.rotations,
          currentTask: SchedulerHookTask.FETCH_LINES,
        }));
      });
    },
    setRotation: (rotation: RotationModel | null) => {
      const selectedLine1 =
        get().tripLines.find((line) => line.id === rotation?.line_id1) ?? null;
      const selectedLine2 =
        get().tripLines.find((line) => line.id === rotation?.line_id2) ?? null;

      set((state) => ({
        ...state,
        selectedRotation: rotation,
        targetTripId: undefined,
        selectedLine1,
        selectedLine2,
      }));
    },

    setSelectedLine1: (selectedLine1: TripLineModel | null) => {
      // const _selectedLine2 =
      //   get().tripLines.find(
      //     (item) =>
      //       item.extrem1_id === selectedLine1?.extrem2_id &&
      //       item.extrem2_id === selectedLine1?.extrem1_id
      //   ) ||
      //   get().tripLines.find(
      //     (item) =>
      //       item.extrem1_id !== selectedLine1?.extrem1_id &&
      //       item.extrem2_id !== selectedLine1?.extrem2_id
      //   );

      const _selectedLine2 = get().selectedLine2;
      const _selectedStopsLine1 = (selectedLine1?.stops || []).map(
        (item) => item.id
      );
      const _selectedStopsLine2 = (_selectedLine2!.stops || []).map(
        (item) => item.id
      );

      set((state) => ({
        ...state,
        targetTripId: undefined,
        selectedLine1,
        // selectedLine2: _selectedLine2,
        selectedStopsLine1: [..._selectedStopsLine1],
        //  selectedStopsLine2: [..._selectedStopsLine2],
        isSameStops: _.isEqual(
          [..._selectedStopsLine1],
          [..._selectedStopsLine2].reverse()
        ),
      }));
    },
    setSelectedLine2: (selectedLine2: TripLineModel | null) => {
      // const _selectedLine1 =
      //   get().tripLines.find(
      //     (item) =>
      //       item.extrem1_id === selectedLine2?.extrem2_id &&
      //       item.extrem2_id === selectedLine2?.extrem1_id
      //   ) ||
      //   get().tripLines.find(
      //     (item) =>
      //       item.extrem1_id !== selectedLine2?.extrem1_id &&
      //       item.extrem2_id !== selectedLine2?.extrem2_id
      //   );

      const _selectedLine1 = get().selectedLine1;

      const _selectedStopsLine1 = (_selectedLine1?.stops || []).map(
        (item) => item.id
      );
      const _selectedStopsLine2 = selectedLine2!.stops.map((item) => item.id);

      set((state) => {
        state.selectedLine2 = selectedLine2;
        // selectedLine1: _selectedLine1,
        // selectedStopsLine1: _selectedStopsLine1,
        state.selectedStopsLine2 = _selectedStopsLine2;
        state.isSameStops = _.isEqual(
          [..._selectedStopsLine1],
          [..._selectedStopsLine2].reverse()
        );
      });
    },
    init: () => {
      set((state) => {
        state.targetTripId = undefined;
        state.currentTask = SchedulerHookTask.INIT;
        state.requestInitStatus = RequestStatusModel.loading;
        state.selectedActivitySite = null;
        state.date = new Date();
        state.selectedLine1 = null;
        state.selectedLine2 = null;
      });

      Promise.all([fetchActivitySites(), fetchTripsLines()])
        .then(([activitySites, tripLines]) => {
          const _selectedLine1 = tripLines.lines[0];
          const _selectedLine2 =
            tripLines.lines.find(
              (item) =>
                item.extrem1_id === _selectedLine1.extrem2_id &&
                item.extrem2_id === _selectedLine1.extrem1_id
            ) ||
            tripLines.lines.find(
              (item) =>
                item.extrem1_id !== _selectedLine1.extrem1_id &&
                item.extrem2_id !== _selectedLine1.extrem2_id
            );

          const line1Stops = _selectedLine1.stops.map((item) => item.id);
          const line2Stops = _selectedLine2!.stops.map((item) => item.id);

          set((state) => {
            state.requestInitStatus = RequestStatusModel.success;
            state.activitySites = activitySites;
            state.tripLines = tripLines.lines;
            state.rotations = tripLines.rotations;
            state.selectedLine1 = _selectedLine1;
            state.selectedLine2 = _selectedLine2 ?? null;
            state.selectedStopsLine1 = line1Stops;
            state.selectedStopsLine2 = line2Stops;
            state.isSameStops = _.isEqual(
              [...line1Stops],
              [...line2Stops].reverse()
            );
          });
        })
        .catch((error) => {
          set((state) => {
            state.requestInitStatus = RequestStatusModel.failure;
          });
        });
    },
    getOrphoOrders: () => {
      set((state) => {
        state.requestFetchOrphoStatus = RequestStatusModel.initial;
      });

      fetchOrphorders(format(get().date, "yyyy-MM-dd"))
        .then((response) => {
          set((state) => {
            state.orphoOrders = response;
            state.requestFetchOrphoStatus = RequestStatusModel.success;
          });
        })
        .catch((error) => {
          set((state) => {
            state.requestFetchOrphoStatus = RequestStatusModel.failure;
          });
        });
    },
    refresh: () => {
      get().fetch({
        date: format(get().date, "yyyy-MM-dd"),
        line_id1: get().selectedLine1!.id,
        line_id2: get().selectedLine2!.id,
      });
    },
    fetch: async (params: FetchTripsParamsModel) => {
      set((state) => {
        state.targetTripId = undefined;
        state.requestSaveOrphoStatus = RequestStatusModel.initial;
        state.requestRazStatus = RequestStatusModel.initial;
        state.requestFetchStatus = RequestStatusModel.loading;
      });

      fetchTrips(params)
        .then((response) => {
          const _dataTrips1 = get().groupByTime(response.trips1);
          const _dataTrips2 = get().groupByTime(response.trips2);
          const _rowDirection =
            Number(Object.keys(_dataTrips1)[0]) <
            Number(Object.keys(_dataTrips2)[0])
              ? RowDirection.ROW
              : RowDirection.ROW_REVERSE;

          set((state) => {
            state.requestFetchStatus = RequestStatusModel.success;
            state.data = response;
            state.dataTrips1 = _dataTrips1;
            state.dataTrips2 = _dataTrips2;
            state.rowDirection = _rowDirection;
          });
        })
        .catch((error) => {
          set((state) => {
            state.requestFetchStatus = RequestStatusModel.failure;
          });
        });
    },
    groupByTime: (trips: RouteModel[]) => {
      return trips.reduce((previousValue, currentValue) => {
        const departureTime = currentValue.departure_time;

        const hour = getHours(
          parse(departureTime, "yyyy-MM-dd HH:mm:ss", new Date())
        );

        const obj = {
          ...previousValue,
          [hour]:
            previousValue[hour] !== undefined
              ? [...previousValue[hour], currentValue]
              : [currentValue],
        };

        obj[hour].sort((item1, item2) => {
          const departureDate1 = parse(
            item1.departure_time,
            "yyyy-MM-dd HH:mm:ss",
            new Date()
          );

          const departureDate2 = parse(
            item2.departure_time,
            "yyyy-MM-dd HH:mm:ss",
            new Date()
          );

          return differenceInMinutes(departureDate1, departureDate2);
        });

        return obj;
      }, {} as Record<number, RouteModel[]>);
    },
    mergeHours: (hours1: string[], hours2: string[]) => {
      const merged = [
        ...hours1.map((key) => Number(key)),
        ...hours2.map((key) => Number(key)),
      ]
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        })
        .sort((a, b) => {
          return a - b;
        });

      return merged;
    },
    razLineCapacity: (lineId: number) => {
      set((state) => {
        state.requestRazStatus = RequestStatusModel.loading;
      });

      resetTripsLine({
        line: lineId,
        date: format(get().date, "yyyy-MM-dd"),
        capacity: 0,
      })
        .then((response) => {
          set((state) => {
            state.requestRazStatus = RequestStatusModel.success;
          });
        })
        .catch((error) => {
          set((state) => {
            state.requestRazStatus = RequestStatusModel.failure;
          });
        });
    },
    saveOrphOrders: (data: { availability: number; order: string }) => {
      set((state) => {
        state.requestSaveOrphoStatus = RequestStatusModel.loading;
      });
      saveOrphorders(data)
        .then((response) => {
          set((state) => {
            state.requestSaveOrphoStatus = response.success
              ? RequestStatusModel.success
              : RequestStatusModel.failure;
          });
        })
        .catch((error) => {
          set((state) => {
            state.requestSaveOrphoStatus = RequestStatusModel.failure;
          });
        });
    },
  }))
);
