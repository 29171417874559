import {
  areIntervalsOverlapping,
  endOfDay,
  format,
  startOfDay,
} from "date-fns";
import { v4 as uuidv4 } from "uuid";
import create from "zustand";
import { immer } from "zustand/middleware/immer";
import { RequestStatusModel } from "../../../application/models/request-status-model";
import { TripLineModel } from "../../../application/models/trip-line-model";
import { VehicleTypeModel } from "../../../application/models/vehicle-model";
import {
  fetchAssignmentOfVehiclesData,
  OrderModel,
  PaxModel,
  RootOrderModel,
  RootVehicleModel,
  saveAffectedVehicles,
  VoyageOrderModel,
} from "../../../application/repositories/assignment-of-vehicles-repository";
import { fetchTripsLines } from "../../../application/repositories/trip_repository";
import { fetchVehicleTypes } from "../../../application/repositories/vehicles_repository";

interface StateModel {
  requestInitStatus: RequestStatusModel;
  requestFetchStatus: RequestStatusModel;
  requestSaveStatus: RequestStatusModel;
  lines: TripLineModel[];
  vehicles: VehicleTypeModel[];
  date: Date;
  startHour: string;
  endHour: string;
  selectedLine: Array<TripLineModel>;
  orders: RootOrderModel[];
  affectedVehicles: RootVehicleModel[];
}

interface ActionsModel {
  init: () => void;
  setDate: (date: Date) => void;
  setSelectedLine: (selectedLine: TripLineModel[]) => void;
  fetch: () => void;
  addEmptyVehicle: (vehicle: RootVehicleModel) => void;
  addOrderToVehicle: (vehicle: RootVehicleModel, order: OrderModel) => void;
  getTotalPaxOrders: (order: RootOrderModel) => number;
  getMergePaxOrders: (order: RootOrderModel) => PaxModel;
  getCapacityVehicle: (
    vihicles: RootVehicleModel,
    order: OrderModel
  ) => boolean;
  getAvailablePlace: (
    vihicles: RootVehicleModel,
    interval: { start: Date; end: Date }
  ) => number;
  getOccupationAffected: (vihicles: RootVehicleModel[]) => number;
  getTotalReservedInVehicle: (vihicles: RootVehicleModel) => number;
  getTotalCapacityVehicles: (vihicles: RootVehicleModel[]) => number;
  getAffectedPaxOrder: (order: OrderModel, trip: string) => number;
  removeAffectedVehicle: (vehicle: RootVehicleModel) => void;
  addPartialOrderToVehicle: (
    vehicle: RootVehicleModel,
    order: VoyageOrderModel
  ) => void;
  getAffectedPaxInTrip: (trip: string) => number;
  getNoAffectedPaxInTrip: (trip: string) => number;
  getNoCompletedPaxOrdersInTrip: (trip: string) => VoyageOrderModel[];
  removeOrderFromVehicle: (
    vihicles: RootVehicleModel,
    order: VoyageOrderModel
  ) => void;
  save: () => void;
  razRequestSaveStatus: () => void;
  setStartHour: (hour: string) => void;
  setEndHour: (hour: string) => void;
}

export const useAssignmentOfVehicles = create(
  immer<StateModel & ActionsModel>((set, get) => ({
    /****************** STATE ******************/

    requestInitStatus: RequestStatusModel.initial,
    requestFetchStatus: RequestStatusModel.initial,
    requestSaveStatus: RequestStatusModel.initial,
    lines: [],
    vehicles: [],
    date: new Date(),
    startHour: format(startOfDay(new Date()), "HH:mm"),
    endHour: format(endOfDay(new Date()), "HH:mm"),
    selectedLine: [],
    orders: [],
    affectedVehicles: [],

    /****************** ACTIONS ******************/

    init: () => {
      set((state) => {
        state.requestInitStatus = RequestStatusModel.loading;
        state.requestFetchStatus = RequestStatusModel.initial;
        state.requestSaveStatus = RequestStatusModel.initial;
        state.date = new Date();
        state.startHour = format(startOfDay(new Date()), "HH:mm");
        state.endHour = format(endOfDay(new Date()), "HH:mm");
        state.lines = [];
        state.vehicles = [];
        state.selectedLine = [];
        state.orders = [];
        state.affectedVehicles = [];
      });

      Promise.all([fetchVehicleTypes(), fetchTripsLines()])
        .then(([vehicles, tripLines]) => {
          set((state) => {
            state.requestInitStatus = RequestStatusModel.success;
            state.lines = tripLines.lines;
            state.vehicles = vehicles;
          });
        })
        .catch((error) => {
          set((state) => {
            state.requestInitStatus = RequestStatusModel.failure;
          });
        });
    },
    setDate: (date: Date) => {
      set((state) => {
        state.date = date;
      });
    },
    setStartHour: (hour: string) => {
      set((state) => {
        state.startHour = hour;
      });
    },
    setEndHour: (hour: string) => {
      set((state) => {
        state.endHour = hour;
      });
    },
    setSelectedLine: (selectedLine: TripLineModel[]) => {
      set((state) => {
        state.selectedLine = selectedLine;
      });
    },
    fetch: () => {
      set((state) => {
        state.requestFetchStatus = RequestStatusModel.loading;
        state.requestSaveStatus = RequestStatusModel.initial;
      });

      fetchAssignmentOfVehiclesData({
        date: format(get().date, "yyyy-MM-dd"),
        lineId: (get().selectedLine || []).map((item) => item.id!),
        h_start: get().startHour,
        h_end: get().endHour,
      })
        .then((response) => {
          set((state) => {
            state.requestFetchStatus = RequestStatusModel.success;
            state.orders = response.orders;
            state.affectedVehicles = response.vehicles;
          });
        })
        .catch((error) => {
          set((state) => {
            state.requestFetchStatus = RequestStatusModel.failure;
          });
        });
    },
    addEmptyVehicle: (vehicle: RootVehicleModel) => {
      set((state) => {
        state.affectedVehicles.push({
          ...vehicle,
          uuid: uuidv4(),
          date: format(get().date, "yyyy-MM-dd"),
        });
      });
    },
    addOrderToVehicle: (vehicle: RootVehicleModel, order: OrderModel) => {
      set((state) => ({
        ...state,
        affectedVehicles: state.affectedVehicles.map((item) => {
          if (
            (item.id === vehicle.id && vehicle.id !== -1) ||
            item.uuid === vehicle.uuid
          ) {
            return {
              ...item,

              pax: {
                ...item.pax,
                total:
                  get().getTotalReservedInVehicle(vehicle) + order.pax.total,
              },
              orders: item.orders.find((_order) => _order.order_id === order.id)
                ? item.orders.map((_order) => {
                    if (_order.order_id === order.id) {
                      return {
                        ..._order,
                        pax: {
                          ..._order.pax,
                          total: _order.pax.total + order.pax.total,
                        },
                      };
                    }
                    return order;
                  })
                : [
                    ...item.orders,
                    {
                      id: order.id,
                      order_id: order.id,
                      order_type: order.type,
                      original: {
                        reference: order.reference,
                        id: order.id,
                        pax: order.pax,
                      },
                      pax: order.pax,
                    },
                  ],
            };
          }
          return item;
        }),
      }));
    },

    addPartialOrderToVehicle: (
      vehicle: RootVehicleModel,
      order: VoyageOrderModel
    ) => {
      set((state) => ({
        ...state,
        affectedVehicles: state.affectedVehicles.map((item) => {
          if (
            (item.id === vehicle.id && vehicle.id !== -1) ||
            item.uuid === vehicle.uuid
          ) {
            return {
              ...item,
              pax: {
                ...item.pax,
                total:
                  get().getTotalReservedInVehicle(vehicle) + order.pax.total,
              },
              orders: item.orders.find((_order) => _order.order_id === order.id)
                ? item.orders.map((_order) => {
                    if (_order.order_id === order.id) {
                      return {
                        ..._order,
                        pax: {
                          ..._order.pax,
                          total: _order.pax.total + order.pax.total,
                        },
                      };
                    }
                    return order;
                  })
                : [
                    ...item.orders,
                    {
                      ...order,
                    },
                  ],
            };
          }
          return item;
        }),
      }));
    },
    getTotalPaxOrders: (order: RootOrderModel) => {
      return order.orders.reduce((acc, cur) => {
        return acc + cur.pax.total;
      }, 0);
    },

    getMergePaxOrders: (order: RootOrderModel) => {
      return order.orders.reduce(
        (acc, cur) => {
          return {
            babies: acc.babies + cur.pax.babies,
            childs: acc.childs + cur.pax.childs,
            adults: acc.adults + cur.pax.adults,
            total: acc.total + cur.pax.total,
          };
        },
        { babies: 0, childs: 0, adults: 0, total: 0 }
      );
    },

    getOccupationAffected: (vihicles: RootVehicleModel[]) => {
      return vihicles.reduce((acc, cur) => {
        return (
          acc +
          cur.orders.reduce((a, c) => {
            return a + c.pax.total;
          }, 0)
        );
      }, 0);
    },
    getAffectedPaxInTrip: (trip: string) => {
      return get()
        .orders.filter((o) => o.trip === trip)
        .reduce((acc, cur) => {
          return (
            acc +
            cur.orders.reduce((a, c) => {
              return a + get().getAffectedPaxOrder(c, trip);
            }, 0)
          );
        }, 0);
    },
    getNoAffectedPaxInTrip: (trip: string) => {
      const rootOrder = get().orders.find((o) => o.trip === trip);
      if (!rootOrder) return 0;
      return rootOrder.orders.reduce((acc, cur) => {
        return acc + cur.pax.total - get().getAffectedPaxOrder(cur, trip);
      }, 0);
    },
    getNoCompletedPaxOrdersInTrip: (trip: string) => {
      const rootOrder = get().orders.find((o) => o.trip === trip);
      if (!rootOrder) return [];
      return rootOrder.orders
        .filter(
          (order) => get().getAffectedPaxOrder(order, trip) < order.pax.total
        )
        .map((order) => ({
          id: order.id,
          order_id: order.id,
          order_type: order.type,
          original: order,
          // type: order.type,
          pax: {
            ...order.pax,
            total: order.pax.total - get().getAffectedPaxOrder(order, trip),
          },
        }));
    },
    getTotalCapacityVehicles: (vihicles: RootVehicleModel[]) => {
      return vihicles.reduce((acc, cur) => {
        return acc + cur.vehicleType.capacity;
      }, 0);
    },
    getTotalReservedInVehicle: (vihicles: RootVehicleModel) => {
      return vihicles.orders.reduce((acc, cur) => {
        return acc + cur.pax.total;
      }, 0);
    },
    getAvailablePlace: (
      vihicles: RootVehicleModel,
      interval: { start: Date; end: Date }
    ) => {
      const totalVehicleCapacity = vihicles.vehicleType.capacity;
      const affectedCapacity = vihicles.orders
        .filter((item) => item.arrivingTime && item.departureTime)
        .filter((affectedOrder) =>
          areIntervalsOverlapping(
            {
              start: new Date(affectedOrder.departureTime!),
              end: new Date(affectedOrder.arrivingTime!),
            },
            {
              start: interval.start,
              end: interval.end,
            }
          )
        )
        .reduce((acc, cur) => {
          return acc + cur.pax.total;
        }, 0);

      return totalVehicleCapacity - affectedCapacity;
    },
    getCapacityVehicle: (vihicles: RootVehicleModel, order: OrderModel) => {
      // const totalVehicleCapacity = vihicles.vehicleType.capacity;
      // const affectedCapacity = vihicles.orders
      //   .filter((item) => item.arrivingTime && item.departureTime)
      //   .filter((affectedOrder) =>
      //     areIntervalsOverlapping(
      //       {
      //         start: new Date(affectedOrder.departureTime!),
      //         end: new Date(affectedOrder.arrivingTime!),
      //       },
      //       {
      //         start: new Date(order.departure_time),
      //         end: new Date(order.arriving_time),
      //       }
      //     )
      //   )
      //   .reduce((acc, cur) => {
      //     return acc + cur.pax.total;
      //   }, 0);

      // console.log({ affectedCapacity });

      return (
        get().getAvailablePlace(vihicles, {
          start: new Date(order.departure_time),
          end: new Date(order.arriving_time),
        }) > order.pax.total
      );
    },
    getAffectedPaxOrder: (order: OrderModel, trip: string) => {
      return get()
        .affectedVehicles.filter((item) => item.trip === trip)
        .reduce((acc, cur) => {
          return (
            acc +
            cur.orders
              .filter((o) => o.order_id === order.id)
              .reduce((a, c) => {
                return a + c.pax.total;
              }, 0)
          );
        }, 0);
    },
    removeAffectedVehicle: (vehicle: RootVehicleModel) => {
      set((state) => {
        state.affectedVehicles = state.affectedVehicles.filter(
          (item) => item.uuid !== vehicle.uuid
        );
      });
    },
    removeOrderFromVehicle: (
      vehicle: RootVehicleModel,
      order: VoyageOrderModel
    ) => {
      set((state) => {
        state.affectedVehicles = state.affectedVehicles.map((v) => {
          if (
            (v.id === vehicle.id && vehicle.id !== -1) ||
            v.uuid === vehicle.uuid
          ) {
            return {
              ...v,
              pax: {
                ...v.pax,
                total: v.pax.total - order.pax.total,
              },
              orders: v.orders.filter((_order) => _order.id !== order.id),
            };
          }
          return v;
        });
      });
    },
    save: () => {
      set((state) => {
        state.requestSaveStatus = RequestStatusModel.loading;
      });

      saveAffectedVehicles({
        date: format(get().date, "yyyy-MM-dd"),
        line_ids: get().selectedLine.map((item) => item.id),
        voyages: get().affectedVehicles.map((item) => ({
          ...item,
          id: item.id === -1 ? null : item.id,
        })),
      })
        .then((response) => {
          set((state) => {
            state.requestSaveStatus = RequestStatusModel.success;
          });
        })
        .catch((error) => {
          set((state) => {
            state.requestSaveStatus = RequestStatusModel.failure;
          });
        });
    },
    razRequestSaveStatus: () => {
      set((state) => {
        state.requestSaveStatus = RequestStatusModel.initial;
      });
    },
  }))
);
