import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { FC } from "react";
import { VehicleModel } from "../../models";
import { useCircuitAndRotations } from "../../state-management/circuit-and-rotations-hook";

export const OrderWidget: FC<{
  vehicle: VehicleModel;
  canBacktrack?: boolean;
}> = ({ vehicle, canBacktrack }): JSX.Element => {
  const { backtrackPlannedOrder } = useCircuitAndRotations();
  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems="stretch"
      sx={{
        border: "solid 1px #eee",
        p: 1,
        width: "100%",
        borderLeft: "solid 3px blue",
        position: "relative",
        "&:hover": {
          backgroundColor: "#F1FAFE",
          cursor: "pointer",
        },
      }}
    >
      {canBacktrack && (
        <Box sx={{ position: "absolute", bottom: -3, right: -5 }}>
          <IconButton
            disableRipple
            size="small"
            color="error"
            aria-label="upload picture"
            component="label"
            onClick={() => backtrackPlannedOrder(vehicle)}
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Stack direction={"column"} spacing={1}>
          <Box>
            <Stack direction={"row"} spacing={1}>
              <Box>
                <Typography variant="body2" noWrap>
                  {vehicle.name.split("->")[0]}
                </Typography>
              </Box>
              <Box>
                <Typography align="center">
                  <ArrowForwardIcon fontSize="small" color="action" />
                </Typography>
              </Box>
              <Box>
                <Typography noWrap>{vehicle.name.split("->")[1]}</Typography>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Stack direction={"row"} spacing={1}>
              <Box>
                <Typography variant="body2" color={"GrayText"} noWrap>
                  {format(new Date(vehicle.start_time), "HH:mm")}
                </Typography>
              </Box>
              <Box></Box>
              <Box>
                <Typography variant="body2" color={"GrayText"} noWrap>
                  {format(new Date(vehicle.end_time), "HH:mm")}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Stack direction={"column"} spacing={1}>
          <Box>
            <Stack direction={"row"} spacing={1}>
              <PeopleOutlineIcon fontSize="small" />
              <Typography align="right">{vehicle.pax.total}</Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
