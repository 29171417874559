import { Box } from "@mui/material";
import { FC } from "react";
import { useDrop } from "react-dnd";

export enum RoundTripType {
  GO = "GO",
  BACK = "BACK",
}

export interface TargetOrderModel {
  index: number;
  roundTripType: RoundTripType;
}

export const TargetOrder: FC<TargetOrderModel> = ({
  index,
  roundTripType,
}): JSX.Element => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ["VEHICLE_MODEL"],
      drop: () => ({ index, roundTripType }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [index]
  );

  const isActive = canDrop && isOver;
  let backgroundColor = "#eee";
  if (isActive) {
    backgroundColor = "secondary.main";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }

  return (
    <Box
      ref={drop}
      sx={{
        height: "100%",
        minHeight: 72,
        width: "100%",
        backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      data-testid="dustbin"
    >
      {isActive ? `Relâchez pour laisser ` : `Faites glisser une case ici`}
    </Box>
  );
};
