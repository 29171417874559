import {
  Button,
  ClickAwayListener,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box, Stack, styled } from "@mui/system";
import { format, parse } from "date-fns";
import { FC, useEffect, useState } from "react";
import {
  RouteModel,
  StopModel,
} from "../../../../application/models/trip-model";
import { useScheduler } from "../../state-management/scheduler-hook";
import * as Scroll from "react-scroll";
import { useDrop } from "react-dnd";
import { DragTypes } from "../orpho-orders";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: 36,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const capacityItems: number[] = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 24, 54, 62, 120, 180, 240, 300,
];

export interface TripTablePropsModel {
  tripsOrder: number;
  hour: number;
  idx: number;
  trip: RouteModel;
  stops: StopModel[];
}

export const TripTable: FC<TripTablePropsModel> = ({
  tripsOrder,
  hour,
  idx,
  trip,
  stops,
}): JSX.Element => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [DragTypes.DRAG_ITEM],
      drop: () => ({ index: trip.id }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [trip]
  );

  const isActive = canDrop && isOver;

  return (
    <Box
      ref={drop}
      sx={{
        position: "relative",
      }}
    >
      {isActive && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "green",
            opacity: 0.5,
          }}
        />
      )}

      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="a dense table" size="small" sx={{ minWidth: 450 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Arrêt</StyledTableCell>
              {(stops || []).map((stop, i) => (
                <StyledTableCell key={i} align="right">
                  {stop.id_str}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Heure
              </StyledTableCell>
              {(stops || []).map((stop, i) => {
                const availability = trip.availability_stops.find(
                  (item) => item.stop_id === stop.id
                );
                const stopHour = format(
                  parse(
                    String(availability?.stop_time),
                    "yyyy-MM-dd HH:mm:ss",
                    new Date()
                  ),
                  "HH:mm"
                );
                return (
                  <StyledTableCell key={i} align="right">
                    {/* {stopHour} */}
                    <TimeInput
                      trip={trip}
                      tripsOrder={tripsOrder}
                      hour={hour}
                      idx={idx}
                      stopId={stop.id}
                      value={stopHour}
                    />
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Capacité
              </StyledTableCell>
              {(stops || []).map((stop, i) => {
                const availability = trip.availability_stops.find(
                  (item) => item.stop_id === stop.id
                );

                return (
                  <StyledTableCell key={i} align="right">
                    {/* {availability?.availability} */}
                    <Capatity
                      trip={trip}
                      tripsOrder={tripsOrder}
                      hour={hour}
                      idx={idx}
                      stopId={stop.id}
                      value={
                        availability!.availability + availability!.reserved
                      }
                    />
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Reservé
              </StyledTableCell>
              {(stops || []).map((stop, i) => {
                const availability = trip.availability_stops.find(
                  (item) => item.stop_id === stop.id
                );

                return (
                  <StyledTableCell
                    key={i}
                    align="right"
                    sx={
                      availability?.reserved! > 0
                        ? { color: "blue", fontWeight: 500 }
                        : { color: "red" }
                    }
                  >
                    {availability?.reserved}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Capatity: FC<{
  trip: RouteModel;
  tripsOrder: number;
  hour: number;
  idx: number;
  stopId: number;
  value: number;
}> = ({ trip, tripsOrder, hour, idx, stopId, value }): JSX.Element => {
  const { changeCapacityInTrips1, changeCapacityInTrips2, setTargetTripId } =
    useScheduler();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    value: number
  ) => {
    if (tripsOrder === 1) {
      changeCapacityInTrips1(hour, idx, stopId, Number(value));
    } else {
      changeCapacityInTrips2(hour, idx, stopId, Number(value));
    }

    setTargetTripId(trip.id);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          float: "right",
          cursor: "pointer",
          width: "fit-content",
        }}
      >
        {value}
      </Box>
      <Popper
        id={id}
        disablePortal={true}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        sx={{
          zIndex: 2000,
          '&[x-placement*="bottom"] $arrow': {
            width: 0,
            height: 0,
            borderLeft: "1em solid transparent",
            borderRight: "1em solid transparent",
            borderBottom: "1em solid #2c3e50",
            marginTop: "-1em",
            "&::before": {
              borderWidth: "0 1em 1em 1em",
              borderColor: "transparent transparent white transparent",
            },
          },
        }}
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box>
            <Box
              ref={setArrowRef}
              sx={{
                position: "absolute",
                display: "inline-block",
                height: 0,
                width: 0,
                borderRight: "6px solid transparent",
                borderBottom: "12px solid #2A3F54",
                borderLeft: "6px solid transparent",
              }}
            />
            <Box sx={{ pt: 1.5 }}>
              <Paper
                sx={{
                  p: 0,
                  backgroundColor: "#F5F5F5",
                  border: "solid 1px #2A3F54",
                  width: 50,
                  maxHeight: 300,
                  overflowY: "scroll",
                }}
                elevation={11}
              >
                <MenuList dense sx={{ width: 50 }}>
                  {capacityItems.map((item) => (
                    <MenuItem
                      key={item}
                      selected={value === item}
                      onClick={(event) => handleMenuItemClick(event, item)}
                    >
                      <ListItemText>{item}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const TimeInput: FC<{
  trip: RouteModel;
  tripsOrder: number;
  hour: number;
  idx: number;
  stopId: number;
  value: string;
}> = ({ trip, tripsOrder, hour, idx, stopId, value }): JSX.Element => {
  const scroller = Scroll.scroller;
  const { changeStopTimeInTrips1, changeStopTimeInTrips2, setTargetTripId } =
    useScheduler();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [timeValue, setTimeValue] = useState<string>(value);

  useEffect(() => {
    setTimeValue(value);
  }, [value]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleChange = (time: string) => {
    if (tripsOrder === 1) {
      changeStopTimeInTrips1(hour, idx, stopId, time);
    } else {
      changeStopTimeInTrips2(hour, idx, stopId, time);
    }

    setTimeout(() => {
      scroller.scrollTo(`${trip.id}`, {
        duration: 500,
        smooth: true,
        offset: -200,
        spy: true,
      });

      setTargetTripId(trip.id);
    }, 100);

    handleClickAway();
  };

  return (
    <>
      <Box
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          float: "right",
          cursor: "pointer",
          width: "fit-content",
        }}
      >
        {value}
      </Box>
      <Popper
        id={id}
        disablePortal={true}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        sx={{
          zIndex: 2000,
          '&[x-placement*="bottom"] $arrow': {
            width: 0,
            height: 0,
            borderLeft: "1em solid transparent",
            borderRight: "1em solid transparent",
            borderBottom: "1em solid #2c3e50",
            marginTop: "-1em",
            "&::before": {
              borderWidth: "0 1em 1em 1em",
              borderColor: "transparent transparent white transparent",
            },
          },
        }}
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box>
            <Box
              ref={setArrowRef}
              sx={{
                position: "absolute",
                display: "inline-block",
                height: 0,
                width: 0,
                borderRight: "6px solid transparent",
                borderBottom: "12px solid #2A3F54",
                borderLeft: "6px solid transparent",
              }}
            />
            <Box sx={{ pt: 1.5 }}>
              <Paper
                sx={{
                  p: 0,
                  backgroundColor: "#F5F5F5",
                  border: "solid 1px #2A3F54",
                }}
                elevation={11}
              >
                <Stack direction={"column"} alignItems="stretch">
                  <TextField
                    size="small"
                    id="time"
                    type="time"
                    value={timeValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setTimeValue(e.target.value)}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    sx={{ width: 114 }}
                  />
                  {timeValue !== value && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => handleChange(timeValue)}
                    >
                      Valider
                    </Button>
                  )}
                </Stack>
              </Paper>
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
