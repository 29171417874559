import {
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import { format, parse } from "date-fns";
import { FC } from "react";
import {
  RouteModel,
  StopModel,
} from "../../../../application/models/trip-model";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    cursor: "pointer",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface SummarizerPropsModel {
  tripsOrder: number;
  stops: StopModel[];
  items: RouteModel[];
  onClick: (item: RouteModel) => void;
}

export const SummarizerListTrips: FC<SummarizerPropsModel> = ({
  stops,
  items,
  onClick,
}): JSX.Element => {
  const getTime = (date: string): string => {
    return format(parse(date, "yyyy-MM-dd HH:mm:ss", new Date()), "HH:mm");
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="a dense table" size="small" sx={{ minWidth: 350 }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Départ</StyledTableCell>
            {(stops || []).map((stop, i) => (
              <StyledTableCell key={i} align="right">
                {stop.id_str}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {(items || []).map((item, index) => (
            <StyledTableRow hover key={index} onClick={() => onClick(item)}>
              <StyledTableCell component="th" scope="row">
                {getTime(item.departure_time)}
              </StyledTableCell>

              {(stops || []).map((stop, i) => {
                const availability = item.availability_stops.find(
                  (item) => item.stop_id === stop.id
                );

                return (
                  <StyledTableCell
                    key={i}
                    align="right"
                    sx={
                      availability?.reserved! > 0
                        ? { color: "blue", fontWeight: 500 }
                        : { color: "red" }
                    }
                  >
                    {availability?.reserved}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
