import axios from "axios";
import { getStorageItem } from "./local-storage";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API,
  //baseURL: window.REACT_APP_SERVER_API,
});

httpClient.interceptors.request.use(
  (request) => {
    const access_token = getStorageItem("ACCESS_TOKEN") || "";
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${access_token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      charset: "utf-8",
    };
    request.withCredentials = false;
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    const { data, status, statusText } = response;
    return { data, status, statusText };
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default httpClient;
