import httpClient from "../../../application/utils/http-client";
import { vehiculesModel, VoyageModel } from "../models";

export const fetchCircuitRotationsVehiculesList = async (params: {
  date: string;
  lines: number[];
}): Promise<{ vehicules: vehiculesModel }> => {
  const url = `trips/vehicules_list`;
  const response = await httpClient
    .post(url, {
      ...{
        date: params.date,
        lines: params.lines,
        private: true,
      },
    })
    .then(({ data }) => data);
  return response;
};

export const fetchCircuitRotationsVoyagesList = async (params: {
  date: string;
}): Promise<{ voyages: VoyageModel[] }> => {
  const url = `trips/voyages_list`;
  const response = await httpClient
    .post(url, {
      ...{
        date: params.date,
      },
    })
    .then(({ data }) => data);
  return response;
};

export const SaveVoyages = async (params: {
  date: string;
  voyages: VoyageModel[];
}): Promise<{ voyages: VoyageModel[] }> => {
  const url = `trips/voyages`;
  const response = await httpClient
    .post(url, {
      ...{
        date: params.date,
        voyages: params.voyages.map((item) => ({
          go_id: item.go?.id,
          back_id: item.back?.id ?? null,
          vehicle_type_id: item.vehicle_type_id ?? item.go?.vehicle_type_id,
        })),
      },
    })
    .then(({ data }) => data);
  return response;
};
