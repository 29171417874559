import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { IconButton, ListItemButton } from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import * as React from "react";
import { VoyageOrderModel } from "../../../../application/repositories/assignment-of-vehicles-repository";

export const AffectedOrdersList: React.FC<{
  orders: VoyageOrderModel[];
  handleRemove: (order: VoyageOrderModel) => void;
}> = ({ orders, handleRemove }): JSX.Element => {
  return (
    <List
      disablePadding
      component="nav"
      dense
      sx={{ width: "100%", bgcolor: "background.paper" }}
      subheader={<ListSubheader>Les Commandes</ListSubheader>}
    >
      {orders.map((item, index) => (
        <ListItemButton key={index}>
          <ListItemText
            id={item.original.reference}
            primary={`${item.original.reference}  : (${item.pax.total})`}
          />
          <IconButton
            size="small"
            color="error"
            onClick={() => handleRemove(item)}
          >
            <ClearOutlinedIcon fontSize="small" />
          </IconButton>
        </ListItemButton>
      ))}
    </List>
  );
};
