import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC, useState } from "react";
import { RequestStatusModel } from "../../../../application/models/request-status-model";
import { DatePickerButton } from "../../../../components/date-picker-button";
import {
  RowDirection,
  useScheduler,
} from "../../state-management/scheduler-hook";
import { RazCapacity } from "../raz-capacity";

export const SchedulerToolBar: FC = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    selectedLine1,
    selectedLine2,
    rowDirection,
    requestSaveStatus,
    showSummarizer,
    setShowSummarizer,
    date,
    dateFin,
    setDateFin,
    save,
  } = useScheduler();

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Appliquer jusqu'au"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 3 }}>
            <DatePickerButton
              minDate={date}
              label="Date"
              value={dateFin}
              onChange={(newValue) => {
                setDateFin(newValue!);
              }}
            />{" "}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              save();
              handleClose();
            }}
            autoFocus
          >
            Accepter
          </Button>
        </DialogActions>
      </Dialog>

      <Stack
        direction={rowDirection}
        alignItems="center"
        justifyContent={"flex-end"}
        spacing={1}
      >
        <Box
          sx={{
            width: "46%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:
              rowDirection === RowDirection.ROW ? "flex-start" : "flex-end",
            backgroundColor: "#F5F5F5",
            height: 50,
            border: "solid 1px #e0e0e0",
            p: 1,
          }}
        >
          {selectedLine1 && <RazCapacity line={selectedLine1} />}
        </Box>

        <Box
          sx={{
            width: "8%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F5F5F5",
            height: 50,
            border: "solid 1px #e0e0e0",
          }}
        >
          <IconButton onClick={() => setShowSummarizer(!showSummarizer)}>
            <ReorderOutlinedIcon />
          </IconButton>

          <IconButton
            color="primary"
            onClick={() => {
              if (requestSaveStatus !== RequestStatusModel.loading) {
                handleClickOpen();
                // save();
              }
            }}
          >
            {requestSaveStatus === RequestStatusModel.loading ? (
              <CircularProgress size={18} sx={{ m: 1 }} />
            ) : (
              <SaveOutlinedIcon />
            )}
          </IconButton>
        </Box>

        <Box
          sx={{
            width: "46%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:
              rowDirection === RowDirection.ROW ? "flex-end" : "flex-start",
            backgroundColor: "#F5F5F5",
            height: 50,
            border: "solid 1px #e0e0e0",
            p: 1,
          }}
        >
          {selectedLine2 && <RazCapacity line={selectedLine2} />}
        </Box>
      </Stack>
    </Box>
  );
};
