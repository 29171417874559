import { Autocomplete, Box, Paper, Stack, TextField } from "@mui/material";
import { FC } from "react";
import { RequestStatusModel } from "../../../../application/models/request-status-model";
import { TripLineModel } from "../../../../application/models/trip-line-model";
import { DatePickerButton } from "../../../../components/date-picker-button";
import { useAssignmentOfVehicles } from "../../state-management/assignment-of-vehicles-hook";
import { FormSkeleton } from "./form-skeleton";

export const AssignmentOfVehiclesFormFilter: FC = (): JSX.Element => {
  const {
    requestInitStatus: requestFetchLinesStatus,
    date,
    startHour,
    endHour,
    lines,
    setSelectedLine,
    selectedLine,
    setDate,
    setStartHour,
    setEndHour,
  } = useAssignmentOfVehicles();

  const handleChange = (newValue: Date) => {
    setDate(newValue);
  };

  if (requestFetchLinesStatus === RequestStatusModel.loading) {
    return <FormSkeleton />;
  }
  return (
    <Paper elevation={0} sx={{ py: 2 }}>
      <Stack direction={"column"} spacing={2} justifyContent="stretch">
        <Box>
          <Stack direction={"row"} spacing={2} justifyContent="center">
            <Box sx={{ width: "40%" }}>
              <Autocomplete
                multiple
                fullWidth
                disablePortal
                id="selectedLine1"
                options={[
                  ...lines,
                  // {
                  //   id: -1,
                  //   id_str: "",
                  //   default_name: "Private",
                  //   uuid: "",
                  //   extrem1_id: 0,
                  //   extrem2_id: 0,
                  //   stops: [],
                  // },
                ]}
                value={selectedLine || null}
                onChange={(event: any, newValue: TripLineModel[]) => {
                  setSelectedLine(newValue);
                }}
                getOptionLabel={(option) => {
                  return option?.default_name;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Ligne de depart" />
                )}
              />
            </Box>
            <Box sx={{ width: "30%" }}>
              <DatePickerButton
                label="Date"
                value={date}
                onChange={(newValue) => {
                  handleChange(newValue!);
                }}
              />
            </Box>

            <Box sx={{ width: "15%" }}>
              <TextField
                fullWidth
                size="small"
                id="time_start"
                label="Heure début"
                type="time"
                value={startHour}
                onChange={(e) => setStartHour(e.target.value)}
                //     defaultValue={format(new Date(), "HH:mm")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Box>
            <Box sx={{ width: "15%" }}>
              <TextField
                fullWidth
                size="small"
                id="time_end"
                label="Heure fin"
                type="time"
                value={endHour}
                //  defaultValue={format(addHours(new Date(), 4), "HH:mm")}
                onChange={(e) => setEndHour(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};
