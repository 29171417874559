import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";

export interface OrderPaxFormPropsModel {
  nbrPalaceRestante: number;
  setSelectedNbrPax: (pax: number) => void;
  cancel: () => void;
}

export const OrderPaxForm: FC<OrderPaxFormPropsModel> = ({
  nbrPalaceRestante,
  setSelectedNbrPax,
  cancel,
}): JSX.Element => {
  const [pax, setPax] = useState(String(nbrPalaceRestante));

  return (
    <Stack direction={"column"} spacing={2} sx={{ width: 400 }}>
      <Typography>
        Le NBR max des passagers autorisés dans cette voiture est{" "}
        <b>{nbrPalaceRestante}</b>
      </Typography>
      <Box>
        <Select
          fullWidth
          id="demo-simple-select"
          value={pax}
          onChange={(event: SelectChangeEvent) => {
            setPax(event.target.value as string);
          }}
        >
          {[...Array(nbrPalaceRestante).keys()].map((i) => (
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Stack direction={"row"} justifyContent="flex-end" spacing={2}>
        <Button onClick={() => cancel()}>Annuler</Button>
        <Button
          color="success"
          disabled={Number(pax) <= 0}
          onClick={() => setSelectedNbrPax(Number(pax))}
          autoFocus
        >
          Accepter
        </Button>
      </Stack>
    </Stack>
  );
};
