import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { FC, useState } from "react";
import { useDrag } from "react-dnd";
import { VehicleModel } from "../../models";
import { useCircuitAndRotations } from "../../state-management/circuit-and-rotations-hook";
import { FusionVehicle } from "../fusion-vehicle";
import { OrderWidget } from "../order-widget";
import { RoundTripType, TargetOrderModel } from "../targetOrder";

export const SourceOrder: FC<{ vehicle: VehicleModel }> = ({
  vehicle,
}): JSX.Element => {
  const { planningTrip, voyages } = useCircuitAndRotations();

  const [dialogProps, setDialogProps] = useState<{
    dialogOpen: boolean;
    otherVehicule: VehicleModel | undefined;
    index: number | null;
    roundTripType: RoundTripType | undefined;
  }>({
    dialogOpen: false,
    otherVehicule: undefined,
    index: null,
    roundTripType: undefined,
  });

  const handleClose = () => {
    setDialogProps({
      dialogOpen: false,
      otherVehicule: undefined,
      index: null,
      roundTripType: undefined,
    });
  };

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: "VEHICLE_MODEL",
      item: { ...vehicle },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<TargetOrderModel>();
        if (item && dropResult) {
          const otherRoundTripType =
            dropResult.roundTripType === RoundTripType.GO ? "back" : "go";

          const roundTrip = voyages[dropResult.index]
            ? voyages[dropResult.index][otherRoundTripType]
            : undefined;

          if (roundTrip && roundTrip.vehicle_type_id !== item.vehicle_type_id) {
            setDialogProps({
              dialogOpen: true,
              otherVehicule: roundTrip,
              index: dropResult.index,
              roundTripType: dropResult.roundTripType,
            });
          } else {
            planningTrip(dropResult.index, dropResult.roundTripType, item);
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [vehicle]
  );

  const opacity = isDragging ? 0.4 : 1;

  return (
    <>
      <Box ref={dragRef} sx={{ width: "100%", opacity }}>
        <OrderWidget vehicle={vehicle} />
      </Box>

      <Dialog
        open={dialogProps.dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Véhicule fusion"}</DialogTitle>
        <DialogContent>
          <FusionVehicle
            currentVehicle={vehicle}
            otherVehicle={dialogProps.otherVehicule!}
            setVehicleType={(vehicleType) => {
              planningTrip(
                dialogProps.index!,
                dialogProps.roundTripType!,
                vehicle,
                vehicleType.id
              );
              handleClose();
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
