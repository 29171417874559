import { Box, Paper, Stack, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useDrag } from "react-dnd";
import { VehicleTypeModel } from "../../../../application/models/vehicle-model";
import { RootOrderModel } from "../../../../application/repositories/assignment-of-vehicles-repository";
import { useAssignmentOfVehicles } from "../../state-management/assignment-of-vehicles-hook";
import { getEmptyImage } from "react-dnd-html5-backend";

export const ListVehicleTypes: FC = (): JSX.Element => {
  const { vehicles } = useAssignmentOfVehicles();

  return (
    <Stack direction={"column"} spacing={1}>
      {vehicles.map((vehicle, index) => (
        <DragerVehicleType key={index} vehicle={vehicle} />
      ))}
    </Stack>
  );
};

export const DragerVehicleType: FC<{ vehicle: VehicleTypeModel }> = ({
  vehicle,
}): JSX.Element => {
  const {
    addEmptyVehicle,
    getNoAffectedPaxInTrip,
    getNoCompletedPaxOrdersInTrip,
  } = useAssignmentOfVehicles();

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      // type: DnDTypeItem.VEHICLE,
      type: String(vehicle.id),
      item: { ...vehicle },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<RootOrderModel>();
        if (item && dropResult) {
          const nbrNoAffectedPaxInTrip = getNoAffectedPaxInTrip(
            dropResult.trip
          );
          const tripOrders = getNoCompletedPaxOrdersInTrip(dropResult.trip);
          if (nbrNoAffectedPaxInTrip <= item.capacity) {
            addEmptyVehicle({
              id: -1,
              uuid: undefined,
              id_str: "",
              trip: dropResult.trip,
              vehicle_type_id: item.id,
              status: "",
              date: "date",
              pax: {
                babies: 0,
                childs: 0,
                adults: 0,
                total: 0,
                // total: nbrNoAffectedPaxInTrip,
              },
              vehicleType: item,
              // orders: [...tripOrders],
              orders: [],
            });
          } else {
            addEmptyVehicle({
              id: -1,
              uuid: undefined,
              id_str: "",
              trip: dropResult.trip,
              vehicle_type_id: item.id,
              status: "",
              date: "date",
              pax: {
                babies: 0,
                childs: 0,
                adults: 0,
                total: 0,
              },
              vehicleType: item,
              orders: [],
            });
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [vehicle]
  );

  useEffect(() => {
    // preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const opacity = isDragging ? 0.4 : 1;

  return (
    <Box
      ref={drag}
      sx={{
        opacity,
        cursor: "pointer",
      }}
      // role={preview ? 'BoxPreview' : 'Box'}
    >
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Stack direction={"row"} justifyContent="space-between">
          <Typography> {vehicle.name}</Typography>
          <Typography>
            {" "}
            Capacité : <b>{vehicle.capacity}</b>
          </Typography>
        </Stack>
        <Box sx={{ pt: 2 }}>
          <Stack direction={"row"}>
            <Typography> Accepte :</Typography>
            {vehicle.vtc && (
              <Typography
                color="textSecondary"
                sx={{
                  mr: 1,
                  pr: 1,
                  borderRight: vehicle.shuttle ? "solid 1px #ddd" : "none",
                }}
              >
                {" "}
                Transfert Privé{" "}
              </Typography>
            )}
            {vehicle.shuttle && (
              <Typography color="textSecondary">Navette et bus</Typography>
            )}
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};
