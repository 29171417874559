import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Paper,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { OrphoOrderModel } from "../../../../application/models/orpho-order-model";
import { RequestStatusModel } from "../../../../application/models/request-status-model";
import { useScheduler } from "../../state-management/scheduler-hook";

export enum DragTypes {
  DRAG_ITEM = "DRAG_ITEM",
}

export const OrphoOrders: FC<{ date: string }> = ({ date }): JSX.Element => {
  const {
    getOrphoOrders,
    orphoOrders,
    saveOrphOrders,
    requestSaveOrphoStatus,
    refresh,
  } = useScheduler((state) => ({
    getOrphoOrders: state.getOrphoOrders,
    orphoOrders: state.orphoOrders,
    saveOrphOrders: state.saveOrphOrders,
    requestSaveOrphoStatus: state.requestSaveOrphoStatus,
    refresh: state.refresh,
  }));

  useEffect(() => {
    getOrphoOrders();
  }, [date]);

  useEffect(() => {
    if (requestSaveOrphoStatus === RequestStatusModel.success) {
      setTimeout(() => {
        refresh();
        getOrphoOrders();
      }, 1000);
    }
  }, [requestSaveOrphoStatus]);

  const save = (data: { availability: number; order: string }) => {
    saveOrphOrders(data);
  };

  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  if (orphoOrders.length === 0) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={{
          width: 190,
          position: "fixed",
          top: "15%",
          left: 2,
          zIndex: 3000,
        }}
      >
        <Button
          fullWidth
          color="error"
          variant="contained"
          endIcon={open ? <CloseIcon /> : <LaunchIcon />}
          onClick={toggleOpen}
        >
          {orphoOrders.length} Commandes
        </Button>

        {open && (
          <Box
            sx={{
              p: 1,
              overflow: "auto",
              maxHeight: "80vh",
              backgroundColor: "#E5E8EA",
            }}
          >
            <Stack direction={"column"} spacing={1}>
              {orphoOrders.map((item, index) => (
                <OrphoWidget key={index} order={item} save={save} />
              ))}
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

/**
 *
 *
 */

const OrphoWidget: FC<{
  order: OrphoOrderModel;
  save: (data: { availability: number; order: string }) => void;
}> = ({ order, save }): JSX.Element => {
  const { requestSaveOrphoStatus } = useScheduler((store) => ({
    requestSaveOrphoStatus: store.requestSaveOrphoStatus,
  }));

  const [state, setState] = useState<{
    openDialog: boolean;

    idTrip?: number;
  }>({
    openDialog: false,

    idTrip: undefined,
  });

  const handleClose = () => {
    setState({
      ...state,
      openDialog: false,

      idTrip: undefined,
    });
  };

  useEffect(() => {
    if (
      requestSaveOrphoStatus === RequestStatusModel.success &&
      state.openDialog === true
    ) {
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
  }, [requestSaveOrphoStatus, state.openDialog]);

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: DragTypes.DRAG_ITEM,
      item: { ...order },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{ index: number }>();
        if (item && dropResult) {
          setState({
            ...state,
            openDialog: true,

            idTrip: dropResult.index,
          });
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [order]
  );

  const opacity = isDragging ? 0.4 : 1;

  return (
    <Paper
      ref={dragRef}
      sx={{ p: 2, position: "relative", opacity, cursor: "pointer" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 5,
          right: 5,
          p: 0.5,
          color: "green",
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>{order.pax}</Typography>
      </Box>
      <Stack direction={"row"} spacing={1}>
        <Box>
          <Typography variant="body2" noWrap>
            {order.from}
          </Typography>
          <Typography variant="caption" noWrap>
            {format(new Date(order.departure_time), "HH:mm")}
          </Typography>
        </Box>
        <Box>
          <Typography align="center">
            <ArrowForwardIcon fontSize="small" color="action" />
          </Typography>
        </Box>
        <Box>
          <Typography noWrap>{order.to}</Typography>
          <Typography variant="caption" noWrap>
            {format(new Date(order.arriving_time), "HH:mm")}
          </Typography>
        </Box>
      </Stack>

      <Dialog
        open={state.openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Affectation du commande "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {requestSaveOrphoStatus === RequestStatusModel.loading && (
              <Box sx={{ mb: 2 }}>
                <LinearProgress />
              </Box>
            )}
            {requestSaveOrphoStatus === RequestStatusModel.failure && (
              <Alert severity="error" sx={{ mb: 2 }}>
                Il se peut que la commande en question ne f'ait pas partie de
                cette ligne
              </Alert>
            )}

            {requestSaveOrphoStatus === RequestStatusModel.success && (
              <Box sx={{ my: 1 }}>
                <Alert severity="success" sx={{ mb: 2 }}>
                  Opération réalisée avec succès{" "}
                </Alert>
              </Box>
            )}

            {requestSaveOrphoStatus !== RequestStatusModel.success && (
              <Typography>
                Vous voulez vraiment affecter la commande : {order.reference} à
                la ligne : {state.idTrip} ?
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            color="success"
            onClick={() => {
              save({ availability: state.idTrip!, order: order.reference });
            }}
            autoFocus
            disabled={requestSaveOrphoStatus === RequestStatusModel.loading}
          >
            Accepter
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
