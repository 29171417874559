import { Box, Stack } from "@mui/material";
import { FC } from "react";
import { BreadcrumbsRouter } from "../../components/breadcrumbs-router";

export const AssignmentOfDriversPage: FC = (): JSX.Element => {
  return (
    <Stack direction={"column"} spacing={3} sx={{ mt: 0 }}>
      <Box sx={{ float: "left" }}>
        <BreadcrumbsRouter />
      </Box>
    </Stack>
  );
};
