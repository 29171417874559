import { Box, Divider, Stack } from "@mui/material";
import { FC } from "react";
import { useAssignmentOfVehicles } from "../../state-management/assignment-of-vehicles-hook";
import { AffectedVehicles } from "../affected-vehicles";
import { OrderCard } from "./order-card";

export const ListOrders: FC = (): JSX.Element => {
  const { orders } = useAssignmentOfVehicles();
  return (
    <Stack
      direction={"column"}
      spacing={2}
      divider={<Divider orientation="horizontal" flexItem />}
    >
      {orders.map((order, index) => (
        <Stack
          direction={"row"}
          spacing={2}
          key={index}
          sx={{ borderLeft: "solid 3px blue" }}
        >
          <Box
            sx={{
              maxHeight: 400,minWidth: 300,
              overflowY: "scroll",
              width: { sm: 250, md: 400 },
            }}
          >
            <OrderCard order={order} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              maxHeight: 400,
              overflowY: "scroll",
              width: { sm: 250, md: 400 },
            }}
          >
            <AffectedVehicles rootOrder={order} />
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};
