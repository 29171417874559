import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { VehicleTypeModel } from "../../../../application/models/vehicle-model";
import { VehicleModel } from "../../models";
import { useCircuitAndRotations } from "../../state-management/circuit-and-rotations-hook";

export const FusionVehicle: FC<{
  currentVehicle: VehicleModel;
  otherVehicle: VehicleModel;
  setVehicleType: (vehicleType: VehicleTypeModel) => void;
}> = ({ currentVehicle, otherVehicle, setVehicleType }): JSX.Element => {
  const { vehiculeTypes } = useCircuitAndRotations();
  const maxCapacity = currentVehicle.vehicleType.capacity;
  const maxConfort = currentVehicle.vehicleType.confort;

  const recommended = vehiculeTypes.filter(
    (item) => item.capacity >= maxCapacity && item.confort >= maxConfort
  );

  return (
    <Box sx={{ width: 500 }}>
      <Stack
        direction={"row"}
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box sx={{ width: "50%" }}>
          <VehicleTypeWidget vehicle={otherVehicle} />
        </Box>
        <Box sx={{ width: "50%" }}>
          <VehicleTypeWidget vehicle={currentVehicle} />
        </Box>
      </Stack>

      <Box
        sx={{ mt: 4, p: 2, backgroundColor: "secondary.main", color: "#fff" }}
      >
        <Typography align="center" sx={{ fontWeight: 500 }}>
          Recommandé(s)
        </Typography>
      </Box>

      {recommended.length > 0 && (
        <Stack
          direction={"column"}
          spacing={1}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          {recommended.map((item, index) => (
            <Stack
              key={index}
              direction={"row"}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Stack direction={"column"}>
                  <Typography sx={{ mt: 2 }}> Type : {item.name}</Typography>
                  <Typography>
                    Capacité : <b>{item.capacity}</b>
                  </Typography>
                  <Typography>
                    Confort : <b>{item.confort}</b>
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Button
                  color="secondary"
                  onClick={() => {
                    setVehicleType(item);
                  }}
                >
                  Sélectionner
                </Button>
              </Box>
            </Stack>
          ))}
        </Stack>
      )}
    </Box>
  );
};

const VehicleTypeWidget: FC<{ vehicle: VehicleModel }> = ({
  vehicle,
}): JSX.Element => {
  return (
    <Stack direction={"column"}>
      <Box sx={{ p: 1, backgroundColor: "primary.main", color: "#fff" }}>
        <Typography align="center" sx={{ fontWeight: 500 }}>
          {vehicle?.name}
        </Typography>
      </Box>

      <Divider />
      <Typography sx={{ mt: 2 }}>
        {" "}
        Type : {vehicle?.vehicleType?.name}
      </Typography>
      <Typography>
        Capacité : <b>{vehicle?.vehicleType?.capacity}</b>
      </Typography>
      <Typography>
        Confort : <b>{vehicle?.vehicleType?.confort}</b>
      </Typography>
    </Stack>
  );
};
