import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Container } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { FC } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { NavBarMenu } from "./components/NavBarMenu";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  height: 110,
}));

export const Layout: FC = (props): JSX.Element => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  React.useEffect(() => {
    if (token) {
      localStorage.setItem("ACCESS_TOKEN", token);
    }
  }, [token]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "background.default",
          color: "#000",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: { md: "flex", xs: "none" },
                flexGrow: 1,
                borderRadius: theme.shape.borderRadius,
                mt: 1,
                p: 2,
                width: "100%",
                backgroundColor: "#fff",
                boxShadow:
                  "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px",
              }}
            >
              <NavBarMenu />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        sx={{
          width: 320,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 320,
            boxSizing: "border-box",
          },
        }}
        transitionDuration={500}
        onClose={handleDrawerClose}
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
      </Drawer>

      <DrawerHeader />
      <Container maxWidth="xl">
        <Box sx={{ mb: 4, mt: { md: 1, xs: 0 } }}>
          <Outlet />
        </Box>
      </Container>
    </Container>
  );
};
