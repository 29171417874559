import httpClient from "../utils/http-client";
import { LinesRotationModel, TripLineModel } from "../models/trip-line-model";
import { TripsModel } from "../models/trip-model";
import { OrphoOrderModel } from "../models/orpho-order-model";

export interface FetchTripsParamsModel {
  date: string;
  line_id1: number;
  line_id2: number;
}

export interface FetchTripsLinesParamsModel {
  activitySiteId: number | undefined;
}

export const fetchTrips = async (
  params: FetchTripsParamsModel
): Promise<TripsModel> => {
  const response = await httpClient
    .get("trips/subtrip/ajax", {
      params: {
        ...params,
      },
    })
    .then(({ data }) => tripsModelFromJson(data));
  return response;
};

export const fetchTripsData = async (
  date: string,
  lineId: number
): Promise<TripLineModel[]> => {
  const url = `trips/tripsdata`;
  const response = await httpClient
    .get(url, { params: { date, line_id: lineId } })
    .then(({ data }) => data);
  return response;
};

const tripsModelFromJson = (data: Record<string, any>) => {
  return {
    trips1: Array.isArray(data["trips1"])
      ? data["trips1"].map((item: any[]) => item[0])
      : [],
    stops1: Array.isArray(data["stops1"]) ? data["stops1"] : [],
    trips2: Array.isArray(data["trips2"])
      ? data["trips2"].map((item: any[]) => item[0])
      : [],
    stops2: Array.isArray(data["stops2"]) ? data["stops2"] : [],
  };
};

export const fetchTripsLines = async (
  activitySiteId?: number
): Promise<LinesRotationModel> => {
  const url =
    activitySiteId !== undefined
      ? `trips/lines/${activitySiteId}`
      : `trips/lines`;
  const response = await httpClient.get(url).then(({ data }) => data);
  return response;
};

export const saveTripsLines = async (data: any): Promise<any> => {
  const url = "trips/subtrip/ajax";
  const response = await httpClient.post(url, data).then(({ data }) => data);
  return response;
};

export const resetTripsLine = async (data: {
  line: number;
  date: string;
  capacity: number;
}): Promise<any> => {
  const url = "trips/reset";
  const response = await httpClient.post(url, data).then(({ data }) => data);
  return response;
};

export const fetchOrphorders = async (
  date: string
): Promise<OrphoOrderModel[]> => {
  const url = `trips/orphorders`;
  const response = await httpClient
    .get(url, { params: { date } })
    .then(({ data }) => data);
  return response;
};

export const saveOrphorders = async (data: {
  availability: number;
  order: string;
}): Promise<{ success: boolean }> => {
  const url = "trips/saveorphorders";
  const response = await httpClient.post(url, data).then(({ data }) => data);
  return response;
};
