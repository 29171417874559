import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";

export interface HourWidgetPropsModel {
  hour: string;
}

export const HourWidget: FC<HourWidgetPropsModel> = ({ hour }): JSX.Element => {
  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        // borderLeft: "solid 5px #eee",
        backgroundColor: "#F5F5F5",
      }}
    >
      <Box
        sx={{
          // borderLeft: "solid 5px #eee",
          p: 1,
          position: "sticky",
          top: 60,
          bottom: 10,
        }}
      >
        <Typography align="center" sx={{ fontWeight: 900, fontSize: 24 }}>
          {" "}
          {hour.length === 2 ? `${hour} H` : `0${hour} H`}
        </Typography>
      </Box>
    </Paper>
  );
};
