import { Alert, LinearProgress } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { format } from "date-fns";
import { FC, useEffect, useMemo } from "react";
import { usePrompt } from "../../application/hooks/usePrompt";
import { RequestStatusModel } from "../../application/models/request-status-model";
import { StopModel } from "../../application/models/trip-model";
import { BlurBackdrop } from "../../components/blurBackdrop";
import { BreadcrumbsRouter } from "../../components/breadcrumbs-router";
import { SchedulerFormFilter } from "./components/form-filter";
import { HourWidget } from "./components/hour-widget";
import { OrphoOrders } from "./components/orpho-orders";
import { SchedulerToolBar } from "./components/toolbar";
import { SummarizerListTrips } from "./components/trip-table/summarizer";
import { TripWidget } from "./components/trip-widget";
import { useScheduler } from "./state-management/scheduler-hook";

const ShuttleManagementPage: FC = (): JSX.Element => {
  const {
    requestFetchStatus,
    requestSaveStatus,

    init,
    resetState,
    fetch,
    mergeHours,
    data,
    dataTrips1,
    dataTrips2,
    date,
    selectedLine1,
    selectedLine2,
    selectedStopsLine1,
    selectedStopsLine2,
    rowDirection,
    showSummarizer,
    isDirty,
  } = useScheduler();

  usePrompt("Voulez vous quitter cette page", isDirty);
  useEffect(() => {
    init();
    return () => {
      resetState();
    };
  }, []);

  const formatedDate = format(date, "yyyy-MM-dd");

  const OrphoOrdersView = useMemo(() => {
    return <OrphoOrders date={formatedDate} />;
  }, [formatedDate]);

  useEffect(() => {
    if (date && selectedLine1 && selectedLine2) {
      fetch({
        date: format(date, "yyyy-MM-dd"),
        line_id1: selectedLine1?.id,
        line_id2: selectedLine2?.id,
      });
    }
  }, [formatedDate, selectedLine1, selectedLine2]);

  return (
    <>
      <Stack direction={"column"} spacing={3} sx={{ mt: 0 }}>
        {OrphoOrdersView}
        <Box sx={{ float: "left" }}>
          {" "}
          <BreadcrumbsRouter />{" "}
        </Box>

        <Box>
          {" "}
          <SchedulerFormFilter />{" "}
        </Box>

        {requestFetchStatus === RequestStatusModel.failure && (
          <Box sx={{ pb: 3 }}>
            <Alert severity="error">Error</Alert>
          </Box>
        )}
        {requestFetchStatus === RequestStatusModel.success &&
          data === undefined && (
            <Box sx={{ pb: 3 }}>
              <Alert severity="info">Aucune donnée disponible!</Alert>
            </Box>
          )}
        {requestFetchStatus === RequestStatusModel.loading && (
          <LinearProgress />
        )}

        {dataTrips1 !== undefined && dataTrips2 !== undefined && (
          <Box sx={{ pb: 15, position: "relative" }}>
            <BlurBackdrop
              open={
                requestFetchStatus === RequestStatusModel.loading ||
                requestSaveStatus === RequestStatusModel.loading
              }
            />
            <Stack direction="column" spacing={1}>
              <SchedulerToolBar />

              {showSummarizer && (
                <Stack
                  direction={rowDirection}
                  alignItems={"stretch"}
                  spacing={1}
                >
                  <Box sx={{ width: "50%" }}>
                    <SummarizerListTrips
                      stops={
                        data?.stops1!.filter((stop) =>
                          selectedStopsLine1.includes(stop.id)
                        ) as StopModel[]
                      }
                      tripsOrder={1}
                      items={
                        Object.values(dataTrips1!).reduce((prev, curr) => {
                          return [...prev, ...curr];
                        }, []) || []
                      }
                      onClick={(item) => {
                        console.log({ item });
                      }}
                    />
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <SummarizerListTrips
                      stops={
                        data?.stops2!.filter((stop) =>
                          selectedStopsLine2.includes(stop.id)
                        ) as StopModel[]
                      }
                      tripsOrder={2}
                      items={
                        Object.values(dataTrips2!).reduce((prev, curr) => {
                          return [...prev, ...curr];
                        }, []) || []
                      }
                      onClick={(item) => {
                        console.log({ item });
                      }}
                    />
                  </Box>
                </Stack>
              )}

              {!showSummarizer &&
                mergeHours(
                  Object.keys(dataTrips1),
                  Object.keys(dataTrips2)
                ).map((hour, index) => (
                  <Stack
                    direction={rowDirection}
                    alignItems={"stretch"}
                    key={index}
                    spacing={1}
                  >
                    <Box
                      sx={{
                        width: "46%",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#eee",
                      }}
                    >
                      {dataTrips1[Number(hour)] !== undefined ? (
                        (dataTrips1[Number(hour)] || []).map((trip, idx) => (
                          <TripWidget
                            tripsOrder={1}
                            hour={Number(hour)}
                            idx={idx}
                            trip={trip}
                            stops={
                              data?.stops1!.filter((stop) =>
                                selectedStopsLine1.includes(stop.id)
                              ) as StopModel[]
                            }
                            key={idx}
                          />
                        ))
                      ) : (
                        <Box
                          sx={{ height: "100%", backgroundColor: "#F5F5F5" }}
                        ></Box>
                      )}
                    </Box>
                    <Box sx={{ width: "8%" }}>
                      <HourWidget hour={`${hour}`} />
                    </Box>

                    <Box
                      sx={{
                        width: "46%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {dataTrips2[Number(hour)] !== undefined ? (
                        (dataTrips2[Number(hour)] || []).map((trip, idx) => (
                          <TripWidget
                            tripsOrder={2}
                            hour={Number(hour)}
                            idx={idx}
                            trip={trip}
                            stops={
                              data?.stops2!.filter((stop) =>
                                selectedStopsLine2.includes(stop.id)
                              ) as StopModel[]
                            }
                            key={idx}
                          />
                        ))
                      ) : (
                        <Box
                          sx={{ height: "100%", backgroundColor: "#F5F5F5" }}
                        ></Box>
                      )}
                    </Box>
                  </Stack>
                ))}
            </Stack>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default ShuttleManagementPage;
