import { createTheme, Theme } from "@mui/material";

const FONT_PRIMARY = "'Ubuntu', sans-serif";

export const theme: Theme = createTheme({
  shape: {
    borderRadius: 0,
  },
  palette: {
    primary: {
      main: "#2A3F54",
    },
    secondary: {
      light: "#0066ff",
      main: "#169F85",

      contrastText: "#ffcc00",
    },
    background: {
      paper: "#fff",
      default: "#fff",
    },
  },
  typography: {
    fontFamily: FONT_PRIMARY,
    h1: {
      fontFamily: FONT_PRIMARY,
      fontSize: 36,
    },
    h2: {
      fontFamily: FONT_PRIMARY,
      fontSize: 30,
    },
    h3: {
      fontFamily: FONT_PRIMARY,
      fontSize: 24,
    },
    h4: {
      fontFamily: FONT_PRIMARY,
      fontSize: 18,
    },
    h5: {
      fontFamily: FONT_PRIMARY,
      fontSize: 14,
    },
    h6: {
      fontFamily: FONT_PRIMARY,
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
      fontFamily: FONT_PRIMARY,
      lineHeight: 1.428,
    },
    body2: {
      fontSize: 14,
      fontFamily: FONT_PRIMARY,
    },
  },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1920,
  //   },
  // },

  shadows: [
    "none",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
    "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;",
  ],
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          outline: "0px solid slategrey",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { textTransform: "none", letterSpacing: 0.5 },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: "translate(13px, -9px) scale(0.80)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "none",
          "&.Mui-disabled": {
            backgroundColor: "#0000000d",
          },
        },
        notchedOutline: {
          backgroundColor: "none",
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          border: "solid 1px red",
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    },
  },
});
