import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { TripLineModel } from "../../../../application/models/trip-line-model";
import { useScheduler } from "../../state-management/scheduler-hook";
import { RequestStatusModel } from "../../../../application/models/request-status-model";
import { Alert, LinearProgress } from "@mui/material";

export const RazCapacity: React.FC<{
  line: TripLineModel;
}> = ({ line }): JSX.Element => {
  const { requestRazStatus, razLineCapacity, refresh } = useScheduler();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (requestRazStatus === RequestStatusModel.success) {
    setTimeout(() => {
      handleClose();
      refresh();
    }, 1000);
  }

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        disabled={requestRazStatus === RequestStatusModel.loading}
      >
        Stop sale: {line.default_name}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>

        <DialogContent>
          {requestRazStatus === RequestStatusModel.loading && (
            <Box sx={{ my: 1 }}>
              <LinearProgress />
            </Box>
          )}
          {requestRazStatus === RequestStatusModel.failure && (
            <Box sx={{ my: 1 }}>
              <Alert severity="error">Error</Alert>
            </Box>
          )}
          {requestRazStatus === RequestStatusModel.success && (
            <Box sx={{ my: 1 }}>
              <Alert severity="success">Opération réalisée avec succès </Alert>
            </Box>
          )}
          {requestRazStatus !== RequestStatusModel.success && (
            <DialogContentText id="alert-dialog-description">
              Êtes-vous sûr de vouloir remettre à zéro la capacité de la ligne :{" "}
              {line.default_name}
            </DialogContentText>
          )}
        </DialogContent>
        {requestRazStatus !== RequestStatusModel.success && (
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              color="primary"
              onClick={() => {
                razLineCapacity(line.id);
              }}
              autoFocus
              disabled={requestRazStatus === RequestStatusModel.loading}
            >
              Accepter
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
};
