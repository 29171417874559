import { Box, Stack } from "@mui/system";
import { FC, useEffect, useState } from "react";
import {
  RouteModel,
  StopModel,
} from "../../../../application/models/trip-model";
import { BlurBackdrop } from "../../../../components/blurBackdrop";
import {
  RowDirection,
  useScheduler,
} from "../../state-management/scheduler-hook";
import { TripActionColumn } from "../trip-action-column";
import { TripTable } from "../trip-table";

interface TripWidgetPropsModel {
  tripsOrder: number;
  hour: number;
  idx: number;
  trip: RouteModel;
  stops: StopModel[];
}

export const TripWidget: FC<TripWidgetPropsModel> = ({
  tripsOrder,
  hour,
  idx,
  trip,
  stops,
}): JSX.Element => {
  const { rowDirection, targetTripId, setTargetTripId } = useScheduler();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (trip.id === targetTripId) {
      setChecked(true);
    }
  }, [trip, targetTripId]);

  return (
    <Box sx={{ position: "relative" }} id={`${trip.id}`}>
      <BlurBackdrop
        open={checked}
        onClose={() => {
          setChecked(false);
          setTargetTripId(undefined);
        }}
      />
      <Stack
        direction={
          tripsOrder === 1
            ? rowDirection
            : rowDirection === RowDirection.ROW
            ? "row-reverse"
            : "row"
        }
      >
        <Box sx={{ width: "80%" }}>
          <TripTable
            tripsOrder={tripsOrder}
            hour={Number(hour)}
            idx={idx}
            trip={trip}
            stops={stops}
          />
        </Box>
        <Box sx={{ width: "20%" }}>
          <TripActionColumn tripsOrder={tripsOrder} trip={trip} />
        </Box>
      </Stack>
    </Box>
  );
};
