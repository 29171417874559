import { Autocomplete, Box, Paper, Stack, TextField } from "@mui/material";
import { FC } from "react";
import { ActivitySiteModel } from "../../../../application/models/activity-site-model";
import { RequestStatusModel } from "../../../../application/models/request-status-model";
import { TripLineModel } from "../../../../application/models/trip-line-model";
import { DatePickerButton } from "../../../../components/date-picker-button";
import SwitchDirectionButton from "../../../../components/switch-direction-button";
import {
  RowDirection,
  useCircuitAndRotations,
} from "../../state-management/circuit-and-rotations-hook";
import { FormSkeleton } from "./form-skeleton";

export const CircuitAndRotationsFormFilter: FC = (): JSX.Element => {
  const {
    requestInitStatus,
    activitySites,
    tripLines,
    selectedLine1,
    selectedLine2,
    date,
    selectedActivitySite,
    rowDirection,
    toggleRowDirection,
    setSelectedLine1,
    setSelectedLine2,
    setDate,
    setSelectedActivitySite,
  } = useCircuitAndRotations();

  const handleChange = (newValue: Date) => {
    setDate(newValue);
  };

  if (requestInitStatus === RequestStatusModel.loading) {
    return <FormSkeleton />;
  }
  return (
    <Paper elevation={0} sx={{ py: 2 }}>
      <Stack direction={"column"} spacing={2} justifyContent="stretch">
        <Box>
          <Stack direction={"row"} spacing={2} justifyContent="center">
            <Box sx={{ width: "50%" }}>
              <Autocomplete
                fullWidth
                disablePortal
                id="activitySites"
                options={activitySites}
                value={selectedActivitySite}
                onChange={(event: any, newValue: ActivitySiteModel | null) => {
                  setSelectedActivitySite(newValue);
                }}
                getOptionLabel={(option) => {
                  return option?.name;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    label="Site d'activité"
                  />
                )}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <DatePickerButton
                label="Date"
                value={date}
                onChange={(newValue) => {
                  handleChange(newValue!);
                }}
              />
            </Box>
          </Stack>
        </Box>
        <Box>
          <Stack
            direction={rowDirection}
            spacing={2}
            justifyContent="center"
            alignItems={"center"}
            sx={{ position: "relative" }}
          >
            <Box sx={{ width: "50%" }}>
              <Autocomplete
                fullWidth
                disablePortal
                id="selectedLine1"
                options={tripLines}
                value={selectedLine1 || null}
                onChange={(event: any, newValue: TripLineModel | null) => {
                  setSelectedLine1(newValue);
                }}
                getOptionLabel={(option) => {
                  return option?.default_name;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Ligne de depart" />
                )}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "10%",
                left: rowDirection === RowDirection.ROW ? "47.8%" : "48.9%",
                zIndex: 5,
              }}
            >
              <SwitchDirectionButton
                switchDirection={() => {
                  toggleRowDirection();
                }}
              />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Autocomplete
                fullWidth
                disablePortal
                id="selectedLine2"
                options={tripLines}
                value={selectedLine2 || null}
                onChange={(event: any, newValue: TripLineModel | null) => {
                  setSelectedLine2(newValue);
                }}
                getOptionLabel={(option) => {
                  return option.default_name;
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Ligne d'arrivée" />
                )}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};
